import React from 'react'
import data, { planType } from '../Data/data'
import './ImgContainer.scss'

function ImgContainer({ active, data, plan, setPlan }) {
  const style = {
    imgContainer: {
      border: plan.name === data.name ? "2px solid rgba(32, 80, 255, 0.80)" : "2px solid #AAA",
      background: plan.name === data.name ? "rgba(32, 80, 255, 0.18)" : "#FFF",
    },
    
    offer: {
     
    }

  }
  return (
    <div 
      className='imgContainerr'
      style={{ ...style.imgContainer }}
      onClick={() => setPlan(data)}
    >
      {(data.name === "Advanced" || data.name === "Annualy") && <div  className='offer'>RECOMMENDED</div>}
      {(data.name === "Standard" ) && <div className='offer'>MOST POPULAR</div>}

      <div className='imgContainer'>
        <img
          src={data.image}
        />
      </div>
      <div className='testImg'>
        <span
          className='textName'
        >{data.name}</span>
        {
          data?.freeDays !== undefined && <span
            style={{
              color: data.freeDays===0?"#B00505":"#05B075",
            }}
            className='freeText'
          > {data.freeDays === 0 ? "" : `+ ${data.freeDays} Days Free`}</span>
        }
          {
          // data?.app !== undefined && <span className='freeText' style={{color:'#05B075'}}> {data.app} Appliances</span>
        }
      </div>
    </div>
  )
}

export default ImgContainer