import React from 'react'
import './BookingAmount.scss'
import Rupee from './Rupee/Rupee'

export default function BookingAmount({remaningAmount=300,refRevoke}) {
  return (
    <div ref={refRevoke} className='booking-Container'>
       <div className='offer-Container'>OFFER</div>
       <div>
          <div className='payBookingText'>Pay booking amount</div>
          <div className='remainingAmount'>₹ {remaningAmount} post installation </div>
       </div>
       <div className='sm:h-[25px] md:h-[50px] flex items-start gap-1'>
       <div style={{
            fontFamily: 'Manrope',
            color: "#363636",
            fontSize: "13.146px",
            fontStyle: "normal",
            fontWeight: "500 ",

        }} 
        className=' sm:leading-[16px] md:leading-[31px]'>₹</div>
       <div className='fixedBookingAmount'> 100</div> 
       </div>
    </div>
  )
}
