import React, { useEffect, useState } from "react";

import "./LeadGeneration.scss";

import AlisteLogo from "../../../assets/LeadGeneration/Aliste_icon.png";

import HeroSectionImage from "../../../assets/LeadGeneration/lead_generation_dekstop_hero.png";
import GetInTouch from "../../../assets/LeadGeneration/lead_generation_success_get_in_touch.webp";
import { useNavigate, useSearchParams } from "react-router-dom";
import Animation from "./Animation";
import InpurtForm from "./InpurtForm";
import { AnimatePresence, motion } from "framer-motion";

export default function LeadGenerationDesktop({ val = false, timeOut = true }) {
  const [submit, setSubmit] = useState(val);
  return (
    <div className="leagBackGroundImage">
      <main className="lead__dk-main-container">
        <div className="lead__dk-container">
          <div className="lead__dk-aliste-logo">
            <img src={AlisteLogo} className="lead__dk-aliste-logo-img" />
          </div>

          <div className="lead__dk-content">
            <div className="lead__dk-content-left">
              <h1>
                Make your home
                <br />
                smart with Aliste
              </h1>
              <p>
                Your Smart Home
                <br />
                is just a few clicks away
              </p>
              <img
                src={HeroSectionImage}
                style={{
                  height: "280px",
                  marginTop: "15px",
                }}
              />
            </div>
            <div className="lead__dk-content-right">
              {submit ? (
                <HeroFormSuccess timeOut={timeOut} />
              ) : (
                <HeroForm setSubmit={setSubmit} />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

function HeroForm({ setSubmit }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const slides = [
    'Transform your existing appliances into smart devices without any rewiring',
    "Our subscription is perfect for rented accommodations as well",
    "We pride ourselves on providing the best customer service",
  ];
  const utmSource = searchParams.get("utm_source");
  const utmName = searchParams.get("utm_user");
  const utmMedium = searchParams.get("utm_medium");
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 4000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="lead__form-dk">
      <h2>
        {utmSource === "Referral"
          ? `Referred by ${utmName ? utmName : utmMedium} 🎉`
          : `Get in touch with us `}
      </h2>
      <AnimatePresence  mode="wait">
        <motion.p
          key={currentSlide}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1,duration:3}}
          exit={{ opacity: 0}}
          transition={{
            ease: "linear",
            delay: 1.2,
          }}
        >
          {slides[currentSlide]}
        </motion.p>
      </AnimatePresence>

      <InpurtForm setSubmit={setSubmit} />
    </div>
  );
}

export const HeroFormSuccess = ({ timeOut }) => {
  const navigate = useNavigate();
  const navigateFun = () => {
    navigate("/");
  };
  useEffect(() => {
    if (!timeOut) return;
    setTimeout(navigateFun, 400);
  }, []);
  return (
    <div className="lead__form-success">
      <div className="lead__form-success-thanks">
        {/* <img src={SuccessIcon} /> */}
        <div className="lead_animation_tick">
          <Animation />
        </div>
        <div className="lead__form-success-thanks-text">
          <h3>Thank You!</h3>
          <p>
            Someone from the team will
            <br />
            get in touch with you shortly
          </p>
        </div>
      </div>

      <div className="lead__form-callback">
        <h3>
          Don’t wait for
          <br />
          the callback from us
        </h3>
        <p>
          Answer 3 simple questions and get the <span>best plan</span> tailored
          to your requirements
        </p>
        <img src={GetInTouch} />

        <button className="lead__callback-button" onClick={() => navigateFun()}>
          Find the best plan
        </button>
      </div>
    </div>
  );
};
