import React from 'react'

function InnerI() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
            <path d="M24.3173 0.0429688C10.9119 0.0429688 0.0429688 10.9158 0.0429688 24.3173C0.0429688 37.7266 10.9119 48.5916 24.3173 48.5916C37.7227 48.5916 48.5916 37.7266 48.5916 24.3173C48.5916 10.9158 37.7227 0.0429688 24.3173 0.0429688ZM24.3173 10.8098C26.5877 10.8098 28.4283 12.6504 28.4283 14.9208C28.4283 17.1912 26.5877 19.0318 24.3173 19.0318C22.0469 19.0318 20.2063 17.1912 20.2063 14.9208C20.2063 12.6504 22.0469 10.8098 24.3173 10.8098ZM29.7986 35.6714C29.7986 36.3201 29.2727 36.846 28.624 36.846H20.0106C19.3619 36.846 18.836 36.3201 18.836 35.6714V33.3223C18.836 32.6736 19.3619 32.1477 20.0106 32.1477H21.1851V25.8834H20.0106C19.3619 25.8834 18.836 25.3575 18.836 24.7088V22.3597C18.836 21.711 19.3619 21.1851 20.0106 21.1851H26.2749C26.9236 21.1851 27.4495 21.711 27.4495 22.3597V32.1477H28.624C29.2727 32.1477 29.7986 32.6736 29.7986 33.3223V35.6714Z" fill="#2552B2" />
        </svg>
    )
}

export default InnerI