import React, { useEffect, useState } from 'react'
import './Banner.scss'
import banner1 from '../../../../../assets/V4/Banner/Power-Saving_big.webp'
import banner2 from '../../../../../assets/V4/Banner/Control_big.webp'
import banner3 from '../../../../../assets/V4/Banner/Voice-Control_big.webp'
import banner4 from '../../../../../assets/V4/Banner/Easy Integration.webp'
import banner5 from '../../../../../assets/V4/Banner/Automation_big.webp'
import banner$Web from '../../../../../assets/V4/Banner/Easy Intergration_big.webp'


function Banner() {
  const mediaMatch = window.matchMedia('(min-width: 760px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  useEffect(()=>{
    window.addEventListener('resize',()=>{
      const mediaMatch = window.matchMedia('(min-width: 760px)');
      setMatches(mediaMatch.matches)
    })
  },[])
  return (
    <div className='innerBannerContainers'>
    <div className='bannerContainers'>
      <div className='innerContainerB'>
        <div className='row'>
        <div className='col-sm-12 col-md-6'>
        <img
          src={banner1}
          className='bannerImg'
        />
        </div>
        <div className='col-sm-12 col-md-6'>
        <img
          src={banner2}
          className='bannerImg'
        />
        </div>
        </div>
        <div className='row md:mt-5 md:mb-5' >
        <div className='col-12'>
        <img
          src={matches?banner$Web:banner4}
          className='bannerImg'
        />
        </div>
        </div>
        <div className='row'>
        <div className='col-sm-12 col-md-6'>
        <img
          src={banner3}
          className='bannerImg'
        />
        </div>
        <div className='col-sm-12 col-md-6'>
        <img
          src={banner5}
          className='bannerImg'
        />
        </div>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Banner