import imgBack from "../../../src/assets/video.png";
import faq2 from "../../../src/assets/faq2.png";
import faq3 from "../../../src/assets/faq3.png";
import blueTick from "../../../src/assets/blueTick.png";
import reviews from "../../../src/assets/review.png";
import review1 from "../../../src/assets/review1.png";
export const dummy = [
  [
    {
      roomName: "Custom",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 0,
            light: 0,
            ac: 0,
            geyser: 0,
            tv: 0,
            socket: 0,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "1 Room",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 0,
            socket: 0,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "1 BHK",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 4,
            socket: 0,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "2 BHK",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 8,
            socket: 0,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "3 BHK",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 0,
            socket: 14,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
  [
    {
      roomName: "4 BHK",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 1,
            light: 1,
            ac: 1,
            geyser: 1,
            tv: 0,
            socket: 18,
            other: 0,
            ir: 0,
          },
        },
      ],
    },
  ],
];

export const FAQ = [
  {
    link: "4y3VGRWqPxI",
    image: imgBack,
    headText: "Can our existing home become smart?",
    subHeadText:
      "Yes, with the right smart devices and installations, we can turn any existing home into a smart home",
  },
  {
    link: "I4KETcT_dyQ",
    image: faq2,
    headText: "How easy it is to use aliste products?",
    subHeadText:
      "Transform your home with Aliste's smart automation and make your life simpler and more convenient.",
  },
  {
    link: "blHX9NVzz5o",
    image: faq3,
    headText: "Can I unsubscribe after buying a plan?",
    subHeadText:
      "Yes, you can unsubscribe from the subscription mangement page from the app to make your life boring !",
  },
];
export const review = [
  {
    link: "l2GWc8zYSDI",
    blueTick: blueTick,
    image: reviews,
    headText: "Nikita Sharma",
    date: "13 Mar 2023",
    subHeadText:
      "Aliste made my parents' life easier with its smart home solutions. They can now control their appliances and lighting with ease.",
  },
  {
    link: "cHBKUoYc6J4",
    blueTick: blueTick,
    image: review1,
    headText: "Suhani Gaur",
    date: "13 Mar 2023",
    subHeadText:
      "Aliste turned my home into a smart and efficient space! With a simple interface and scheduling feature, it's highly recommended!",
  },
];
