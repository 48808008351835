import { questions } from "./actionType"

export const loadPlan =(data)=>{
    return {
        type:questions.LOAD_PLAN,
        payload:data
    }
}
export const loadTypeOfInstallation =(data)=>{
    return {
        type:questions.LOAD_TYPE_OF_INSTALLATION,
        payload:data
    }
}
export const loadFrequency =(data)=>{
    return {
        type:questions.LOAD_SELECTED_FREQUENCY,
        payload:data
    }
}
export const loadBasicDetails = (data)=>{
    return {
        type:questions.LOAD_BASIC_DETAILS,
        payload:data
    }
}