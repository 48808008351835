import React, { useEffect, useState } from 'react'
import Model from './comom/Model/Model'
import { planFrequencyImages, planType, planTypeImage } from './Data/data'
import MainV3 from '../MainV3'
import Loading from './comom/Model/Loading'
import WebApp from './WebApp'
import ImgContainer from './comom/ImgContainer'
import ImgContainer1 from './comom/Model/ImgContainer1'
import Arrow from './comom/Model/Arrow'
import Back from './Back'
import Loadder from './comom/Model/Loadder'
import { useDispatch, useSelector } from 'react-redux'
import { loadFrequency, loadPlan, loadTypeOfInstallation } from '../../redux/questionAcrion'
import FormModel from './comom/Model/FormModel'
import { useLocation } from 'react-router-dom'

function MainV4() {
    const dispatch = useDispatch()
    const location = useLocation()
    const plan = useSelector(state=>state.questionPlan)
    const typeOfInstallation = useSelector(state=>state.questionTypeofIstallation)
    const selectedFrequency = useSelector(state=>state.questionFrequency)
    let questionUser = useSelector(state => state.questionUser)
   
    const [page,setPage]= useState(location.pathname==="/plan"?5:1)
    const mediaMatch = window.matchMedia('(min-width: 760px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const setPlan =(data)=>{
        dispatch(loadPlan(data))
    }
    const setTypeOfInstallation =(data)=>{
      dispatch(loadTypeOfInstallation(data))
  }
  const setSelectedFrequency =(data)=>{
    dispatch(loadFrequency(data))
  }
  useState(()=>{
    window.addEventListener('resize',()=>{
      const mediaMatch = window.matchMedia('(min-width: 760px)');
      setMatches(mediaMatch.matches)
    })
},[])
  useEffect(()=>{
    if(Object.keys(plan).length!==0 && Object.keys(questionUser).length!==0 && page!==4){
      if(Object.keys(selectedFrequency).length!==0){
        setPage(5)
      }
    }
  },[])
    const [show,setShow] = useState(false)
    const changePage = ()=>{
      
      if(page==4){
        setShow(true)
        setTimeout(()=>{
          setShow(false)
          setPage(page+1)
        }, 3000)
      }else{
        if(page===3 && Object.keys(questionUser).length!==0){
          setShow(true)
          setTimeout(()=>{
            setShow(false)
            setPage(page+2)
          }, 3000)
        }else{
        setPage(page+1)
        }
      }
    }
  const onBack =()=>{
    setPage(page-1)
  }
  return (
    <>

    { page===1 && <Model 
       data={planType} 
       plan={plan} 
       setPlan={setPlan} 
       btnName={"Next Step"} 
       OnClick={changePage} 
       page={'01'} 
       width={'25%'} 
       question={"Which of these best matches your home?"} 
       ImgContainer={ImgContainer1}
       delay={matches?0:0.5}
       durationTime={matches?0:0.7}
      />
    }
    { page===2 && <Model 
       data={planTypeImage[plan.name]} 
       plan={typeOfInstallation} 
       setPlan={setTypeOfInstallation} 
       btnName={"Next Step"} 
       OnClick={changePage} 
       page={'02'} 
       width={'50%'} 
       question={"What level of automation would you like?"} 
       ImgContainer={ImgContainer}
       onBack={onBack}
       Arrow={Arrow}
       delay={0}
       durationTime={0}
      />}
    { page===3 && <Model 
        data={planFrequencyImages} 
        plan={selectedFrequency} 
        setPlan={setSelectedFrequency} 
        btnName={"Find the best plan"} 
        OnClick={changePage} 
        page={'03'} 
        width={'75%'} 
        question={"Please select preferred billing cycle?"} 
        ImgContainer={ImgContainer}
        onBack={onBack}
        Arrow={Arrow}
        delay={0}
       durationTime={0}
      />}
    {
      page===4 && <FormModel
       onBack={onBack}
       OnClick={changePage}
       setShow={setShow}
      />
    }
    {
      show && <Loading/>
    }
   { page!==5 && <Back/>}
    {page==5 &&<WebApp plan={plan} typeOfInstallation={typeOfInstallation} selectedFrequency={selectedFrequency} page={page}/> }
    
    </>
  )
}

export default MainV4