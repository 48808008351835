import React, { useEffect, useState } from 'react'
import './FormModel.scss'
import { cityArray } from '../../../MobileData'
import userIcon from '../../../../../assets/V4/inputBox/profile.png'
import locationIcon from '../../../../../assets/V4/inputBox/location.png'
import contactIcon from '../../../../../assets/V4/inputBox/contact.png'
import { CaretDown } from 'react-bootstrap-icons'
import { isValidNumber } from '../../../MobileSummary'
import Arrow from './Arrow'
import { useDispatch, useSelector } from 'react-redux'
import { loadBasicDetails } from '../../../../redux/questionAcrion'
import axios from 'axios'
import { serverUrl } from '../../../../../ApiUrl'
import { trackContact } from '../../../../../utils/fb'


function FormModel({ OnClick, onBack, setShow }) {
    const [name, setName] = useState('')
    const plan = useSelector((state) => state.questionPlan);
    const typeOfInstallation = useSelector(
      (state) => state.questionTypeofIstallation
    );
    const selectedFrequency = useSelector((state) => state.questionFrequency);
    const [nameError, setNameError] = useState(false)
    const [phone, setPhone] = useState("")
    const [phoneError, setPhoneError] = useState(true)
    const [city, setCity] = useState('Select City')
    const [cityError, setCityError] = useState(false)
    const [selectPopMobileCity, setSelectPopMobileCity] = useState(false)
    const mediaMatch = window.matchMedia('(min-width: 760px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    console.log(plan,"plan",typeOfInstallation,"type","sel",selectedFrequency);
    useEffect(()=>{
        window.addEventListener('resize',()=>{
          const mediaMatch = window.matchMedia('(min-width: 760px)');
          setMatches(mediaMatch.matches)
        })
      },[])
    const dispatch = useDispatch()

    const checkNumber = (number) => {
        let res = isValidNumber(number)
        if (res) {
            setPhoneError(res)
        }

    }
    const checkData = (click = false) => {
        let res = isValidNumber(phone)

        let c = false
        let n = false
        if (city === "Select City") {

            c = true
        } else if (city !== "Select City") {

            c = false
        }
        if (name === "") {

            n = true
        } else if (name !== "") {
            n = false
        }
        if (click) {
            if (res === true && n === false && c === false) {
            
                setShow(true)
            } 
            if (res !== phoneError) {
                setPhoneError(res)
            }
            if (c !== cityArray) {
                setCityError(c)
            }
            if (n !== nameError) {
                setNameError(n)
            }
        }
        if (res === true && n === false && c === false) {
            
            return true
        } else {
            return false
        }
    }
    

    return (
        <div className='formModelContainer'>
            <div className='innerFormContainer'>
                <div className='numberContainer relative'>
                    <span className='arrow' onClick={onBack}> <Arrow /></span>

                    <span className='boldNumber'>{"04"}</span>
                    <span className='smallNumber' style={{ fontSize: "25.217px", letterSpacing: "0.025px" }}>/</span>
                    <span className='smallNumber' style={{ display: "flex", alignItems: 'flex-end', paddingBottom: "3.5px" }}>04</span>
                </div>
                <div className='lineProgressBar'>
                    <div className='lineProgrssfull'></div>
                    <div className='lineNotFull' style={{ width: "100%" }}></div>
                </div>
                <div className='qustionTextContainer' style={{ marginBottom: `${matches ? ("20px") : ("22px")}` }}>
                    {"Please fill in your basic details"}
                </div>
                <form className='modelForm'>
                    <div
                        className="input-container-img-inputBox"
                        style={{
                            border: "0.5px solid #00000054"
                        }}
                    >
                        <div className="img-container">
                            <img

                                src={userIcon}
                                alt={"name"}
                            />
                        </div>
                        <input
                            type="text"
                            className=""
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                                if (e.target.value !== "") {
                                    setNameError(false)
                                }
                            }}
                            placeholder="Your Name"
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            style={{
                                color: "black",
                            }}
                        />
                    </div>
                    {nameError === true ? (
                        <div className="field-error">
                            Please enter a valid Name.
                        </div>
                    ) : null}
                    <div
                        className="input-container-img-inputBox"
                        onClick={() => setSelectPopMobileCity(!selectPopMobileCity)}
                        style={{
                            border:
                                cityError === false
                                    ? "0.5px solid #00000054"
                                    : "0.5px solid #fe0000",
                        }}
                    >
                        <div className="img-container">
                            <img
                                src={locationIcon}
                                alt={"name"}
                            />
                        </div>
                        <div>

                            <div
                                style={{ color: city === "Select City" ? "#9fa6b2" : "black", fontWeight: 400 }}
                                className='cityName'
                            >
                                {city}
                            </div>
                            <CaretDown
                                color="black"

                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "20px",
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>
                    {cityError === true ? (
                        <div className="field-error">
                            Please enter a valid city.
                        </div>
                    ) : null}
                    {selectPopMobileCity === true ? (
                        <div className="popBox"
                            style={{
                                border: "0.5px solid #00000054"
                            }}
                        >
                            {cityArray.filter((ci) => ci.value !== city).map(
                                (ci) => {
                                    return (
                                        <div
                                            className="selectedValue cityName"
                                            style={{
                                                height: "43px",
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                fontWeight: 500,
                                                color: "black"
                                            }}
                                            onClick={() => {
                                                setCity(ci.value);
                                                setSelectPopMobileCity(false);
                                                setCityError(false)
                                            }}
                                        >
                                            {ci.value}

                                        </div>
                                    );
                                }
                            )}
                        </div>
                    ) : null}

                    <div
                        className="input-container-img-inputBox"
                        style={{
                            border: phoneError === false ? "0.5px solid #fe0000" : "0.5px solid #00000054"
                        }}
                    >
                        <div className="img-container">
                            <img

                                src={contactIcon}
                                alt={"phone"}
                            />
                        </div>
                        <input
                            type="text"

                            placeholder="Phone number"
                            onChange={(e) => {
                                setPhone(e.target.value)
                                checkNumber(e.target.value)
                            }}
                            aria-label="phone"
                            onBlur={(e) => checkNumber(e.target.value)}
                            aria-describedby="basic-addon1"
                            style={{ color: "black" }}

                        />
                    </div>
                    {phoneError === false ? (
                        <div className="field-error">
                            Please enter a valid phone number.
                        </div>
                    ) : null}
                    <div
                        className='form-button'
                        onClick={() => {
                            if (checkData(true)) {
                                dispatch(loadBasicDetails({
                                    name:name,
                                    city:city,
                                    phone:phone
                                }))
                                OnClick()
                                trackContact({
                                    name:name,
                                    email:'',
                                    phone:phone,
                                    city:city,
                                    page:'main-webapp-page',
                                    note:`Room Size:- ${plan?.name}, Appliances:- ${typeOfInstallation?.app}, Automaion level:- ${typeOfInstallation?.name}, Frequency:- ${selectedFrequency?.name}`
                                })
                            }}}
                    style={{
                        backgroundColor: !checkData() ? "rgba(0, 0, 0, 0.125)" : "#2050FF",

                    }}
                    >Find the best plan</div>
        </form>
            </div >
        </div >
    )
}

export default FormModel