import React, { useEffect, useState } from "react";
import "./SelectedPlan.scss";
import Bhk1 from "../../../../../assets/V4/1room_full.png";
import Model from "../Model/Model";
import { planFrequencyImages, planType, planTypeImage } from "../../Data/data";
import ImgContainer1 from "../Model/ImgContainer1";
import ImgContainer from "../ImgContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  loadFrequency,
  loadPlan,
  loadTypeOfInstallation,
} from "../../../../redux/questionAcrion";
import { setDate } from "rsuite/esm/utils/dateUtils";
const PlanContainer = ({ header, subHeader, imgs, setPage, page }) => {
  const sub = subHeader?.split(" ");
  if (sub === undefined || sub.length === 0) return;
  return (
    <div className="planContainer main-main">
      <div className="planContainer main-rotate"></div>
      <div className="planContainer sub-rotate" onClick={() => setPage(page)}>
        <div className="header">{header}</div>
        <div className="subHeader">
          <span className="heeadddd">{sub[0]}</span>
          <span> </span>
          <span>{sub[1]}</span>
        </div>
        <img src={imgs} />
      </div>
    </div>
  );
};

const DesktopPlanDetail = ({
  header = "House Size",
  subHeader = "1 Room",
  imgs = { Bhk1 },
}) => {
  return (
    <div className="selectedPlanDetail">
      <div className="subHeader">{header}</div>
      <div className="header">{subHeader}</div>
      <div className="img-container">
        <img src={imgs} />
      </div>
    </div>
  );
};
const DesktopPlanDetailName = ({
  data = {},
  plan = {},
  setPlan,
  type = "",
}) => {
  const sub = data.name?.split(" ");
  return (
    <div
      className="planContainerName"
      style={{
        border: data.name === plan.name ? "1px solid #2050FF80" : "",
        backgroundColor: data.name === plan.name ? "#2050FF18" : "#FFFFFF",
        width: `${type === "installationtype" && "130px"}`,
      }}
      onClick={() => setPlan(data)}
    >
      <span className="headFirst">{sub[0]}</span>

      <span className="headSecond">{sub[1]}</span>
    </div>
  );
};

function SelectedPlan() {
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.questionPlan);
  const typeOfInstallation = useSelector(
    (state) => state.questionTypeofIstallation
  );
  const selectedFrequency = useSelector((state) => state.questionFrequency);
  const setPlan = (data) => {
    dispatch(loadPlan(data));
    dispatch(loadTypeOfInstallation(planTypeImage[data.name][0]));
  };
  const setTypeOfInstallation = (data) => {
    dispatch(loadTypeOfInstallation(data));
  };
  const setFrequency = (data) => {
    dispatch(loadFrequency(data));
  };
  const [page, setPage] = useState(0);
  const changePage = () => {
    setPage(0);
  };
  useEffect(() => {
    if (Object.keys(plan).length === 0) {
      setPlan(planType[0]);
    }
    if (
      Object.keys(plan).length !== 0 &&
      Object.keys(typeOfInstallation).length === 0
    ) {
      setTypeOfInstallation(planTypeImage[plan]);
    }
    if (
      Object.keys(plan).length === 0 &&
      Object.keys(typeOfInstallation).length === 0
    ) {
      setTypeOfInstallation(planTypeImage[planType[0].name]);
    }
    if (Object.keys(selectedFrequency).length === 0) {
      setFrequency(planFrequencyImages[0]);
    }
  }, []);
  return (
    <>
      {window.matchMedia("(max-width: 460px)").matches ? (
        <div className="selectedPlan">
          <PlanContainer
            header={"House Size"}
            subHeader={plan.name}
            imgs={plan.image}
            setPage={setPage}
            page={1}
          />
          <PlanContainer
            header={"Automated Area"}
            subHeader={typeOfInstallation.name}
            imgs={typeOfInstallation.image}
            setPage={setPage}
            page={2}
          />
          {page === 1 && (
            <Model
              data={planType}
              plan={plan}
              setPlan={setPlan}
              btnName={"Done"}
              OnClick={changePage}
              page={"01"}
              width={"33%"}
              question={"Which of these best matches your home?"}
              ImgContainer={ImgContainer1}
            />
          )}
          {page === 2 && (
            <Model
              data={planTypeImage[plan.name]}
              plan={typeOfInstallation}
              setPlan={setTypeOfInstallation}
              btnName={"Done"}
              OnClick={changePage}
              page={"02"}
              width={"66%"}
              question={"What level of automation would you like?"}
              ImgContainer={ImgContainer}
            />
          )}
        </div>
      ) : (
        <div className="selectedPlanContainer">
          <div className="selectplan">
            <DesktopPlanDetail
              header={"House Size"}
              subHeader={plan.name}
              imgs={plan.image}
            />
            <div className="selectedPlanDetailOption">
              {planType.map((e) => (
                <DesktopPlanDetailName data={e} plan={plan} setPlan={setPlan} />
              ))}
            </div>
          </div>
          <div className="selectplan">
            <DesktopPlanDetail
              header={"Automated Area"}
              subHeader={typeOfInstallation.name}
              imgs={typeOfInstallation.image}
            />
            <div className="selectedPlanDetailOption">
              {Object.keys(plan).length !== 0 &&
                planTypeImage[plan.name].map((e) => (
                  <DesktopPlanDetailName
                    data={e}
                    plan={typeOfInstallation}
                    setPlan={setTypeOfInstallation}
                    type={"installationtype"}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectedPlan;
