import { alisteLead } from "./misc";

let user;
let formFilledUpdated = false;

export const faqOpened = (details) => {
  window?.FM?.trackCustomEvent("FAQOpened", {
    ...details,
    email: user?.em,
  });
  window.fbq("trackCustom", "FAQOpened", {
    ud: user,
    cd: details,
  });
  window.gtag("event", "select_content", {
    content_type: "faq",
    item_id: details._id,
  });
};

// inidicate that the user typed atleast one character in the form
export const formFillStart = () => {
  if (formFilledUpdated) return;
  window?.FM?.trackCustomEvent("FormFillStart", {
    email: user?.em,
  });
  window.fbq("trackCustom", "FormFillStart", {
    ud: user,
  });
  window.gtag("event", "form_start", {
    content_type: "form_fill_start",
  });
};

export const carouselOpened = (details) => {
  window?.FM?.trackCustomEvent("CarouselOpened", {
    ...details,
    email: user?.em,
  });
  window.fbq("trackCustom", "CarouselOpened", {
    ud: user,
    cd: details,
  });
  window.gtag("event", "select_content", {
    content_type: "carousel_opened",
    item_id: details._id,
  });
};

export const reviewOpened = (details) => {
  window?.FM?.trackCustomEvent("ReviewOpened", {
    ...details,
    email: user?.em,
  });
  window.fbq("trackCustom", "ReviewOpened", {
    ud: user,
    cd: details,
  });
  window.gtag("event", "select_content", {
    content_type: "review",
    item_id: details._id,
  });
};

export const trackContact = (details) => {
  let urlParams = new URLSearchParams(window.location.search);

  const formattedUrl = new URLSearchParams(window.location.search.replaceAll('+', ''));

  let utm = {
    source: urlParams.get("utm_source") || "",
    campaign: urlParams.get("utm_campaign") || "",
    medium: urlParams.get("utm_medium") || "",
    content: urlParams.get('utm_content') || '',
    adName: urlParams.get('name') || '',
    employeeCode: urlParams.get('employeeCode') || ''
  };
  
  if (!utm.adName) utm.adName = formattedUrl.get('ad+name');

  

  let name_split = details.name.split(" ");
  let fn = details.name;
  let ln = "";
  if (name_split.length > 1) {
    fn = name_split.slice(0, name_split.length - 1).join(" ");
    ln = name_split[name_split.length - 1];
  }
  user = {
    em: details.email,
    ph: details.phone,
    fn,
    ln,
    ct: details.city,
  };
  let leadSource = utm.source ? `${utm.content} ${utm.adName}` : "Webapp";

  var new_contact = {
    "First name": fn, //Replace with first name of the user
    "Last name": ln, //Replace with last name of the user
    Email: user.em, //Replace with email of the user
    "Mobile Number": user.ph, //Replace with a custom field
    Phone: user.ph, //Replace with a custom field
    City: user.ct,
    Source: "webapp-subscription",
    "Lead Source": leadSource,
    "Dynamic Lead Source": leadSource,
    "UTM Source": utm.source,
    "UTM Campaign": utm.campaign,
    "UTM Medium": utm.medium,
    "UTM Content": utm.content,
    "UTM Adname": utm.adName,
    "employeeCode": utm.employeeCode
  };
  const contact = {
    name: details.name,
    email: user.em,
    phone: user.ph,
    city: user.ct,
    note:details.note?details.note:'',
    utm: {
      source: utm.source || leadSource,
      campaign: utm.campaign || "",
      medium: utm.medium,
      content: utm.content,
      adname: utm.adName,
      page: details.page?details.page:"Webapp",
      employeeCode: utm.employeeCode
    },
  };

  alisteLead(contact);
  var identifier = user.em;
  window.gtag("event", "form_submit", user);
  window.fwcrm.identify(identifier, new_contact);
  window.fbq("track", "Lead", {
    ud: user,
  });
  window.gtag("set", "user_properties", {
    address: {
      name: details.name,
      first_name: fn,
      last_name: ln,
      city: details.city,
    },
    city: details.city,
    name: details.name,
    email: details.email,
    phone_number: details.phone,
  });
  window.gtag("set", "user_data", {
    address: {
      name: details.name,
      first_name: fn,
      last_name: ln,
      city: details.city,
    },
    city: details.city,
    name: details.name,
    email: details.email,
    phone_number: details.phone,
  });
};

export const addToCart = (details) => {
  let fee = 0;
  let { appliances } = details;
  let installation = 300 + Math.max(0, (appliances - 10) * 20);
  if (details.planId === "Monthly") {
    fee = appliances * 30;
  } else if (details.planId === "Quarterly") {
    fee = appliances * 90;
  } else if (details.planId === "Semi Annualy") {
    fee = appliances * 180;
  } else if (details.planId === "Annualy") {
    fee = appliances * 365;
  } else if (details.planId === "Test (Weekly)") {
    fee = appliances * 7;
  }
  let total = (fee + installation) * 1.18 + appliances * 100;
  let newDetails = {
    ...details,
    total,
    fee,
  };
  window.gtag("event", "add_to_cart", {
    currency: "INR",
    value: total,
    items: [
      {
        item_id: `${details.packageId}/${details.planId}/${details.appliances}`,
        item_name: "Automation",
        currency: "INR",
        index: 0,
        price: details.total,
        quantity: 1,
      },
    ],
  });
  window.fbq("track", "AddToCart", {
    ud: user,
    cd: newDetails,
  });
};

export const userClickedContact = (details) => {
  window.fbq("track", "Contact", {
    ud: user,
  });
};

export const initiateCheckout = (details) => {
  window.FM.trackCustomEvent("Initiate Checkout", {
    ...details,
    email: user?.em,
    price: details.total,
    currency: "INR",
  });
  window.gtag("event", "begin_checkout", {
    currency: "INR",
    value: details.total,
    coupon: "",
    items: [
      {
        item_id: `${details.packageId}/${details.planId}/${details.appliances}`,
        item_name: "Automation",
        currency: "INR",
        index: 0,
        price: details.total,
        quantity: 1,
      },
    ],
  });
  window.fbq("track", "InitiateCheckout", {
    ud: user,
    cd: details,
  });
};

export const packageOpened = (
  details = {
    packageId: "",
    packageName: "",
    appliances: 0,
  }
) => {
  window?.FM?.trackCustomEvent("PackageOpened", {
    ...details,
    email: user?.em,
  });
  window.fbq("trackCustom", "PackageOpened", {
    ud: user,
    cd: details,
  });
  window.gtag("event", "select_content", {
    content_type: "package",
    item_id: details.packageId,
  });
};

export const planOpened = (
  details = {
    packageId: "",
    packageName: "",
    appliances: 0,
    planId: "",
    planName: "",
  }
) => {
  window?.FM?.trackCustomEvent("PlanOpened", {
    ...details,
    email: user?.em,
  });
  window.fbq("trackCustom", "PlanOpened", {
    ud: user,
    cd: details,
  });
  window.gtag("event", "select_content", {
    content_type: "plan",
    item_id: details.planId,
  });
};

export const pageView = (details = { name: "", location: "" }) => {
  window?.FM?.trackCustomEvent("PageView", {
    ...details,
    email: user?.em,
  });
  window.gtag("event", "page_view", {
    page_title: details.name,
    page_location: details.location, // Include the full URL
  });
};

export const WhatsAppClicked = () => {
  window.FM.trackCustomEvent("WhatsApp_Webapp", {
    email: user?.em,
  });
  window.gtag("event", "whatsapp_webapp", {});
  window.fbq("trackCustom", "WhatsAppWebapp", {});
};

export const CallUsClicked = () => {};
export const PhoneClicked = () => {
  window.FM.trackCustomEvent("PhoneClicked", {
    email: user?.em,
  });
  window.gtag("event", "phone_webapp", {});
  window.fbq("trackCustom", "PhoneWebapp", {});
};

export const SMLinkedIn = () => {
  window.FM.trackCustomEvent("SM_LinkedIn_Webapp", {
    email: user?.em,
  });
  window.gtag("event", "sm_linkedin_webapp", {});
  window.fbq("trackCustom", "SMLinkedInWebapp", {});
};

export const CallToKnowMore = () => {
  window.FM.trackCustomEvent("Call_Us_Clicked", {
    email: user?.em,
  });
  window.gtag("event", "call_us_clicked", {});
  window.fbq("trackCustom", "CallUsClicked", {});
};

export const SMFacebook = () => {
  window.FM.trackCustomEvent("SM_Facebook_Webapp", {
    email: user?.em,
  });
  window.gtag("event", "sm_facebook_webapp", {});
  window.fbq("trackCustom", "SMFacebookWebapp", {});
};

export const SMWhatsApp = () => {
  window.FM.trackCustomEvent("SM_WhatsApp_Webapp", {
    email: user?.em,
  });
  window.gtag("event", "sm_whatsapp_webapp", {});
  window.fbq("trackCustom", "SMWhatsAppWebapp", {});
};

export const SMInstagram = () => {
  window.FM.trackCustomEvent("SM_Instagram_Webapp", {
    email: user?.em,
  });
  window.gtag("event", "sm_instagram_webapp", {});
  window.fbq("trackCustom", "SMInstagramWebapp", {});
};

export const subscribed = (details) => {
  window.FM.trackCustomEvent("Purchase", {
    ...details,
    email: user?.em,
  });
  window.fbq(
    "track",
    "Subscribe",
    {
      value: details.total,
      currency: "INR",
      ...details,
    },
    { eventID: `converted_${details.generatedHex}` }
  );
  window.fbq(
    "track",
    "Purchase",
    {
      value: details.total,
      currency: "INR",
      ...details,
    },
    { eventID: `converted_${details.generatedHex}` }
  );
  window.gtag("event", "purchase", {
    transaction_id: details.generatedHex || "",
    affiliation: "",
    value: details.total,
    tax: details.tax,
    shipping: details.installation,
    currency: "INR",
    items: [
      {
        item_id: `${details.packageId}/${details.planId}/${details.appliances}`,
        item_name: "Automation",
        currency: "INR",
        index: 0,
        price: details.total,
        quantity: 1,
      },
    ],
  });
};

export const viewContent = () => {};
