import React, { useEffect, useRef, useState } from "react";
import Header from "./comom/Header/Header";
import SelectedPlan from "./comom/SelectedPlan/SelectedPlan";
import PlanDetail from "./comom/PlanDetail/PlanDetail";
import OurMarket from "../../small Component/OurMarket";
import CommonHeader from "./comom/Header/CommonHeader";
import "./WebApp.scss";
import OurClients from "../../small Component/OurClients";
import FAQ from "./comom/FAQ/FAQ";
import CoustomerReview from "./comom/customerReview/CoustomerReview";
import ProductHighLight from "./comom/ProductHighLight/ProductHighLight";
import { useParams } from "react-router-dom";
import Banner from "./comom/Banner/Banner";
import CommonSubHeader from "./comom/Header/CommonSubHeader";
import { Footer as WebFooter } from "../../Mobile/Main";
import MiddleBanner from "./comom/MiddleBanner/MiddleBanner";

function WebApp() {
  let { phone } = useParams();
  const [isSticky, setSticky] = useState(false);
  const refSticky = useRef(null);
  const refRevoke = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (refSticky.current) {
        const elementRect = refSticky.current.getBoundingClientRect();
        if (elementRect.top <= 120) {
          setSticky(true);
        }
      }
      if (refRevoke.current) {
        const elementRect2 = refRevoke.current.getBoundingClientRect();
        if (elementRect2.top > -32) {
          setSticky(false);
        }
      }
    };

    document
      .querySelector(".fontWIos")
      .addEventListener("scroll", handleScroll);

    // return () => {
    //     document.querySelector('.fontWIos').removeEventListener('scroll', handleScroll);
    // };
  }, []);

  return (
    <div className="scrollbar fontWIos mainContainer">
      <Header isSticky={isSticky} />
      {window.matchMedia("(max-width: 460px)").matches === false && (
        <div className="web-subHeader">
          <CommonHeader text={"Automating  your existing appliances"} />
          <CommonSubHeader
            text={"We don't do any rewiring, our devices simply goes behind your switchBox"}
          />
        </div>
      )}
      <div style={{ padding: "0% 5%", width: "100%" }}>
        <div className="web-selectedPlan-PlanDetail-Container">
          <SelectedPlan />
          <PlanDetail
            refSticky={refSticky}
            isSticky={isSticky}
            refRevoke={refRevoke}
            referal={phone}
          />
        </div>
      </div>
      {/* <UserExperience /> */}
      <div className="AsSeen-Container">
        <div className="innerASeen">
          <div className="md:pt-3 md:pb-3">
            <CommonHeader
              text="As seen on"
              fs={
                window.matchMedia("(max-width: 460px)").matches
                  ? "16px"
                  : "26px"
              }
              mL={window.matchMedia("(max-width: 460px)").matches ? "5%" : "0%"}
            />
          </div>
          <OurMarket color={true} mT={0} bg={"#FFF"} bs={"none"} />
        </div>
      </div>
      <div className="ProductHeighLight">
        <div className="innerProductHeighLight">
          <CommonHeader text="Product Highlights" />
          <ProductHighLight />
        </div>
      </div>
      <MiddleBanner />
      <Banner />
      <div className="OurClinets">
        <OurClients color={true} mT={0} />
      </div>
      <div className="webCustomerReview">
        <FAQ />
      </div>
      <div className="webCustomerReview">
        <CoustomerReview />
      </div>
      {/* {window.matchMedia("(max-width: 460px)").matches?<Footer />:<WebFooter/>} */}
      <WebFooter />
    </div>
  );
}

export default WebApp;
