import axios from "axios";
import { serverUrl } from "../../ApiUrl";

export const getVoucherStatusApi = async (code, billValue) => {
  return await axios
    .post(`${serverUrl.giftr}/getStatus`, {
      voucherCode: code.current.value,
      billValue: billValue,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { success: false, message: "something went wrong" };
    });
};

export const consumeVocherCodeApi = async (email, phone, code, billAmount) => {
  return await axios
    .post(`${serverUrl.giftr}/consume`, {
      phone: phone,
      email: email,
      voucherCode: code,
      billValue: billAmount,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { success: false, message: "something went wrong" };
    });
};

export const registerVoucherClient = async (detail) => {
  await axios
    .post(`${serverUrl.u}/api/zoho/registerPartialOfflinePaidClient`, detail)
    .then(async (res) => {
      if (res.data.success === true) {
        await UpdateHex(
          detail.transactionId,
          res.data.data.subscription.generatedHex
        );
      }
    })
    .catch((err) => {});
};

const UpdateHex = async (code, hex) => {
  await axios
    .post(`${serverUrl.giftr}/updateHex`, {
      voucherCode: code,
      subscriptionHex: hex,
    })
    .then((res) => {
      if (res.data.success === true) {
        window.location = `voucher/${hex}`;
      }
    })
    .catch((err) => {
    });
};
