import React from 'react'
import './MiddleBanner.scss'
import world from '../../../../../assets/V4/MiddelBanner/Control from anywhere.webp'
import installation from '../../../../../assets/V4/MiddelBanner/Installation in 48 hours.webp'
import warranty from '../../../../../assets/V4/MiddelBanner/Life Time Warranty.webp'
import rewiring from '../../../../../assets/V4/MiddelBanner/No rewiring required.webp'

let data = [
  {
    text:"Control from anywhere",
    img:world
  },
  {
    text:"Life Time Warranty",
    img:warranty
  },
  {
    text:"Installation in 48 hours",
    img:installation
  },
  {
    text:"No rewiring required",
    img:rewiring
  },
]

const IconWithText = ({imgs={world},text="rr"})=>{
  return (
    <>
    <div className='IconTextContainer'>
      <img
        src={imgs}
      />
      <div className='middleBannerText'>{text}</div>
    </div>
    <hr className='hesss'></hr>
    </>
  )
}

function MiddleBanner() {
  return (
    <div className='middleBannerContainer'>
      <div className='innerMIddleContainer'>
        <div className='innerinnerMiddleContainer'>
        {
          data.map((d)=>(
             <IconWithText key={String(d.img)} imgs={d.img} text={d.text}/>
          ))
        }
        </div>
      </div>
    </div>
  )
}

export default MiddleBanner