import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Main.css";
import { fixedAppliances } from "./MobileData";
import { serverUrl } from "../../ApiUrl";
import { mobileViewPlanDetail } from "../redux/action";
import { useDispatch, useSelector } from "react-redux";
import MobileSummary from "./MobileSummary";
import Slider from "../Courosel/Slider";
import Gpay from "../small Component/Gpay";
import Banner from "../small Component/Banner";
import Creatives from "../small Component/Creatives";
import Feature from "../small Component/Feature";
import room from "../../assets/Plan/ROOM.png";
import Bhk1 from "../../assets/Plan/1BHK.png";
import Bhk2 from "../../assets/Plan/2BHK.png";
import Bhk3 from "../../assets/Plan/3BHK.png";
import Bhk4 from "../../assets/Plan/4BHK.png";
import Bhk5 from "../../assets/Plan/5BHK.png";
import whiteTick from "../../assets/Plan/WhiteTick.png";
import {
  CallToKnowMore,
  faqOpened,
  packageOpened,
  pageView,
  PhoneClicked,
  reviewOpened,
  SMFacebook,
  SMInstagram,
  SMLinkedIn,
  SMWhatsApp,
  WhatsAppClicked,
} from "../../utils/fb";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube";
import moment from "moment";
import { faqDataGet } from "../ApiCalls/getFaqData";
import { reviewDataGet } from "../ApiCalls/getReviewData";
import OurClients from "../small Component/OurClients";
import OurMarket from "../small Component/OurMarket";
const packages = {
  "1Room": {
    packageId: 1,
    packageName: "1 Room Plan",
    appliances: 4,
    img: room,
    first: "1",
    second: "Room",
    key: "Room1Plan",
  },
  "1BHK": {
    packageId: 2,
    packageName: "1 BHK Plan",
    appliances: 8,
    img: Bhk1,
    first: "1",
    second: "BHK",
    key: "Bhk1Plan",
  },
  "2BHK": {
    packageId: 3,
    packageName: "2 BHK Plan",
    appliances: 12,
    img: Bhk2,
    first: "2",
    second: "BHK",
    key: "Bhk2Plan",
  },
  "3BHK": {
    packageId: 4,
    packageName: "3 BHK Plan",
    appliances: 18,
    img: Bhk3,
    first: "3",
    second: "BHK",
    key: "Bhk3Plan",
  },
  "4BHK": {
    packageId: 5,
    packageName: "4 BHK Plan",
    appliances: 22,
    img: Bhk4,
    first: "4",
    second: "BHK",
    key: "Bhk4Plan",
  },
  "5BHK": {
    packageId: 6,
    packageName: "5 BHK Plan",
    appliances: 26,
    img: Bhk5,
    first: "5",
    second: "BHK",
    key: "Bhk5Plan",
  },
};
const style = {
  planMainContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5%",
  },
  planContainer: {
    border: "1px solid rgba(48, 82, 172, 0.60",
    width: "30%",
    display: "flex",
    borderRadius: "12px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "55px",
  },
  planText: {
    color: "rgba(14, 17, 42, 0.90)",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    marginTop: "14px",
  },
  planImg: {
    width: "55.197px",
    // height: "37.344px",
    position: "absolute",
    top: "-71%",
  },
  planSelected: {
    height: "43px",
    borderRadius: "12px",
    background: "#2552B2",
    marginTop: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "3%",
  },
  whiteTickImg: {
    width: "14px",
    height: "9px",
  },
  seletedPlanText: {
    color: "#FFF",
    fontFamily: "Manrope",
    fontSize: "14.783px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20.469px",
  },
  planBrackDownText: {
    color: "#0E112A",
    fontFamily: "Manrope",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
    marginTop: "27px",
  },
  planBrackDownSubText: {
    color: "rgba(14, 17, 42, 0.70)",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "17.879px",
    marginTop: "9px",
  },
  durationContainer: {
    display: "flex",
    gap: "11px",
    marginTop: "28px",
    overflowY: "scroll",
  },
  singleDurationContainer: {
    height: "36px",
    borderRadius: "37px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 19px",
    whiteSpace: "nowrap",
  },
  durationTime: {
    color: "#000",
    fontFamily: "Manrope",
    fontSize: "11.395px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "17.907px",
  },
  durationCost: {
    color: "#208C4B",
    fontFamily: "Manrope",
    fontSize: "11.395px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16.279px",
    marginLeft: "4px",
    whiteSpace: "nowrap",
  },
};
export const duration = [
  {
    planName: "Monthly",
    duration: "Monthly",
    cost: 2,
    days: 30,
    freeDays: 0,
  },
  {
    planName: "Quarterly",
    duration: "3 Months",
    cost: 2,
    days: 90,
    freeDays: 0,
  },

  {
    planName: "Semi Annualy",
    duration: "6 Months",
    cost: 2,
    days: 180,
    freeDays: 20,
  },
  {
    planName: "Annualy",
    duration: "12 Months",
    cost: 2,
    days: 365,
    freeDays: 60,
  },
];
export default function MainV3() {
  const dispatch = useDispatch();
  let { phone } = useParams();
  let [links, setLinks] = useState("");
  let data = useSelector((state) => state.mobilePlanData);
  let faqData = useSelector((state) => state.faqData);
  let [costs, setCosts] = useState(duration[1]);
  let reviewData = useSelector((state) => state.reviewData);
  let [radios, setRadio] = useState({ ...data, refferal: phone });
  const [installation, setInsallation] = useState(300);
  useEffect(() => {
    dispatch(mobileViewPlanDetail(radios));
  }, [radios, dispatch]);
  useEffect(() => {
    if (radios.appliances <= 10) {
      setInsallation(300);
    } else if (radios.appliances > 10) {
      let app = radios.appliances - 10;
      setInsallation(300 + app * 20);
    }
  }, [radios]);
  useEffect(() => {
    pageView({ name: "Select Package", location: window.location.toString() });
    faqDataGet(dispatch);
    reviewDataGet(dispatch);
  }, []);
  let urlParams = new URLSearchParams(window.location.search);
  let utm = {
    source: urlParams.get("utm_source") || "",
    campaign: urlParams.get("utm_campaign") || "",
    medium: urlParams.get("utm_medium") || "",
  };
  let utmQuery = "";
  if (utm.source !== "") {
    utmQuery = `?utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`;
  }
  const opts = {
    height: "500",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  function onReady(event) {
    event.target.pauseVideo();
  }
  return (
    <div className="main-background" style={{ background: "#E5EAF5" }}>
      <div className="Header_container">
        <div className="logo_Contaniner">
          <img
            src={process.env.PUBLIC_URL + "/MobileAssets/logo.png"}
            alt="logo"
          />
        </div>
        <div className="CompanyName" style={{ color: "#000" }}>
          Aliste
        </div>
        <div className="phone-what-container">
          <div className="phone_Contaniner">
            <a
              href={`tel:18002128806`}
              target="_blank"
              style={{ textDecoration: "none", color: "#000" }}
              onClick={PhoneClicked}
            >
              <img
                src={
                  process.env.PUBLIC_URL + "/MobileAssets/PhoneIconBlack.png"
                }
                alt="logo"
              />
            </a>
          </div>
          <div className="whatapp_Contaniner">
            <a
              href={`http://wa.me/+919664861809?text=Hi`}
              target="_blank"
              onClick={WhatsAppClicked}
              style={{ textDecoration: "none", color: "white" }}
            >
              <img
                src={process.env.PUBLIC_URL + "/MobileAssets/Whatapp.png"}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="main-header-hr"></div>
      <div className="wab-container-top">
        <div className="wab-container-top-slider">
          <Slider />
        </div>

        <div className="wab-container-top-other">
          <Gpay color={true} />
          <div className="Automation_text_container">
            <div className="Automation_text" style={{ color: "#000" }}>
              Smart Home at{" "}
              <strong className="Pay300" style={{ color: "#000" }}>
                ₹ 1
              </strong>
              <span className="automation-slashDay" style={{ color: "#000" }}>
                /day
              </span>
            </div>
            <div
              className="Automation_subtext"
              style={{ color: "rgba(14, 17, 42, 0.70)" }}
            >
              Choose a suitable automation plan for your home.{" "}
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="main-selectPlan-text-d" id="plan">
        <div>
          Select <span>a Plan and</span> Pay
        </div>
      </div>
      <div className="both-screen">
        <div className="main-mainScreen">
          <div
            style={{ ...style.planMainContainer, width: "90%", margin: "auto" }}
          >
            {Object.keys(packages).map((e, i) => (
              <div
                style={{
                  ...style.planContainer,
                  marginTop: i > 2 ? "33px" : "0px",
                  backgroundColor: radios[packages[e].key] ? "#2552B2" : "",
                }}
                key={e}
                onClick={() => {
                  packageOpened(packages[e]);
                  setRadio({
                    Room1Plan: packages[e].key === "Room1Plan" ? true : false,
                    Bhk1Plan: packages[e].key === "Bhk1Plan" ? true : false,
                    Bhk2Plan: packages[e].key === "Bhk2Plan" ? true : false,
                    Bhk3Plan: packages[e].key === "Bhk3Plan" ? true : false,
                    Bhk4Plan: packages[e].key === "Bhk4Plan" ? true : false,
                    Bhk5Plan: packages[e].key === "Bhk5Plan" ? true : false,
                    appliances: packages[e].appliances,
                    rate: packages[e].appliances * 30,
                    planName: packages[e].packageName,
                    planId: packages[e].packageId,
                  });
                }}
              >
                <img src={packages[e].img} style={style.planImg} />

                <div
                  style={{
                    ...style.planText,
                    color: radios[packages[e].key] ? "#FFF" : "",
                  }}
                >
                  <span style={{ fontWeight: 800 }}>{packages[e].first}</span>{" "}
                  {packages[e].second}
                </div>
              </div>
            ))}
          </div>
          {Object.keys(radios).length > 1 && (
            <>
              <div
                style={{
                  ...style.planSelected,
                  width: "90%",
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                <div style={{ lineHeight: "20.400px", display: "flex" }}>
                  <img src={whiteTick} style={style.whiteTickImg} />
                </div>
                <div style={style.seletedPlanText}>
                  Automate{" "}
                  <span style={{ fontWeight: 700 }}>{radios.appliances}</span>{" "}
                  appliances in your house
                </div>
              </div>
            </>
          )}
          <OurMarket color={true} />
          {Object.keys(radios).length > 1 && (
            <>
              <div style={{ width: "90%", margin: "auto" }}>
                <div style={style.planBrackDownText}>Plan Breakdown</div>
                <div style={style.planBrackDownSubText}>
                  Choose a suitable automation plan for your home.
                </div>
                <div style={style.durationContainer} className="scrollbar">
                  {duration.map((e) => (
                    <div
                      style={{
                        ...style.singleDurationContainer,
                        backgroundColor:
                          costs.days === e.days ? "#2552B2" : "white",
                      }}
                      onClick={() => setCosts(e)}
                    >
                      <span
                        style={{
                          ...style.durationTime,
                          color: costs.days === e.days ? "white" : "black",
                        }}
                      >
                        {e.duration}{" "}
                      </span>

                      <span
                        style={{
                          ...style.durationCost,
                          color: costs.days === e.days ? "#0CDA5E" : "#208C4B",
                        }}
                      >
                        {" "}
                        ₹ {e.cost}/day
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="mSummary-plan-Summary"
                style={{
                  overflow: "hidden",
                  background: "white",
                  width: "90%",
                  margin: "auto",
                  marginTop: "22px",
                }}
              >
                <div
                  className="mSummary-plan-Summary-upperDetail"
                  style={{ background: "white" }}
                >
                  <div className="mSummary-single-detail-container">
                    <div
                      className="mSummary-single-detail-name"
                      style={{ color: "black" }}
                    >
                      Subscription Rate
                    </div>
                    <div
                      className="mSummary-single-detail-result"
                      style={{ color: "black" }}
                    >
                      ₹ {costs.cost}/day
                    </div>
                  </div>
                  <div className="mSummary-single-detail-container">
                    <div
                      className="mSummary-single-detail-name"
                      style={{ color: "black" }}
                    >
                      Plan Fee
                    </div>
                    <div
                      className="mSummary-single-detail-result"
                      style={{ color: "black" }}
                    >
                      ₹{" "}
                      {radios.appliances
                        ? radios.appliances * costs.days * costs.cost
                        : 0}
                    </div>
                  </div>
                  <div className="mSummary-single-detail-container">
                    <div
                      className="mSummary-single-detail-name"
                      style={{ color: "black" }}
                    >
                      Security deposit (Refundable)
                    </div>
                    <div
                      className="mSummary-single-detail-result"
                      style={{ color: "black" }}
                    >
                      ₹ {radios.appliances ? radios.appliances * 100 : 0}
                    </div>
                  </div>
                  <div className="mSummary-single-detail-container">
                    <div
                      className="mSummary-single-detail-name"
                      style={{ color: "black" }}
                    >
                      Installation fee
                    </div>
                    <div
                      className="mSummary-single-detail-result"
                      style={{ color: "black" }}
                    >
                      ₹ {installation}
                    </div>
                  </div>

                  <div className="mSummary-single-detail-container">
                    <div
                      className="mSummary-single-detail-name"
                      style={{ color: "black" }}
                    >
                      Taxes & charges
                    </div>
                    <div
                      className="mSummary-single-detail-result"
                      style={{ color: "black" }}
                    >
                      ₹{" "}
                      {radios.appliances
                        ? (
                            (radios.appliances * costs.days + installation) *
                            0.18
                          ).toFixed(2)
                        : 0}
                    </div>
                  </div>
                </div>
                <div
                  className="mSummary-plan-Summary-lowerDetail"
                  style={{
                    background: "white",
                    borderTop: "1px solid rgba(48, 82, 172, 0.10)",
                  }}
                >
                  <div
                    className="mSummary-plan-Total-container"
                    style={{ background: "white" }}
                  >
                    <div
                      className="mSummary-plan-Total-text"
                      style={{ color: "black" }}
                    >
                      Total
                    </div>
                    <div
                      className="mSummary-plan-Total-result"
                      style={{ color: "black" }}
                    >
                      ₹
                      {radios.appliances
                        ? (
                            (radios.appliances * costs.days + installation) *
                              1.18 +
                            radios.appliances * 100
                          ).toFixed(2)
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="mSummary-plan-partial-summary"
                style={{
                  border: "none",
                  background:
                    "linear-gradient(270deg, rgba(0, 96, 92, 0.40) -44.66%, rgba(0, 255, 77, 0.17) 104.56%, rgba(0, 111, 44, 0.40) 104.57%), #FFF",
                  width: "90%",
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                <span className="mSummary-partial-offer">
                  <span className="mSummary-partial-offer-text">OFFER</span>
                </span>
                <div className="mSummary-plan-patial">
                  <div className="mSummary-partial-post-text-container">
                    <div
                      className="mSummary-partial-post-text"
                      style={{ color: "black" }}
                    >
                      Pay booking amount
                    </div>
                    <div
                      className="mSummary-partial-post-amount"
                      style={{ color: "#0E7E19" }}
                    >
                      ₹{" "}
                      {data.appliances
                        ? (
                            (radios.appliances * costs.days * costs.cost +
                              installation) *
                              1.18 +
                            radios?.appliances * 100 -
                            100
                          ).toFixed(2)
                        : 0}{" "}
                      post installation
                    </div>
                  </div>
                  <div
                    className="mSummary-partial-post-amount-result"
                    style={{ color: "black" }}
                  >
                    ₹ 100
                  </div>
                </div>
              </div>
            </>
          )}

          {/* onClick={() => {
              packageOpened(packages["4BHK"]);
              setRadio({
                Room1Plan: false,
                Bhk1Plan: false,
                Bhk2Plan: false,
                Bhk3Plan: false,
                Bhk4Plan: true,
                appliances: fixedAppliances.bhk4,
                rate: fixedAppliances.bhk4 * 30,
                planName: "4 BHK Plan",
                planId: 5,
              }); */}
        </div>
        <div className="main-mobile-Summary">
          {Object.keys(radios).length !== 0 ? (
            // <MobileSummary />
            <></>
          ) : (
            <div>
              <div className="main-plan-notSeleted-text-subHead">
                No plan <span style={{ fontWeight: "800" }}>Selected</span>
              </div>
              <div className="main-plan-notSeleted-text-subHeading">
                Select a plan to know more
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Pay Button */}
      {Object.keys(radios).length !== 0 && radios?.appliances !== undefined ? (
        <div
          className="main-payButton-container"
          style={{
            background: "white",
            width: "100%",
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
          }}
        >
          <Link
            // to={`/summary${utmQuery ? utmQuery : ""}`}
            to={`/summary${utmQuery ? utmQuery : ""}`}
            state={{ path: "3", cost: costs }}
            style={{ textDecoration: "none", width: "90%", margin: "auto" }}
          >
            <div
              className="main-payButton-btn-container"
              style={{ width: "100%", marginTop: 0 }}
              onClick={() => dispatch(mobileViewPlanDetail(radios))}
            >
              <div
                className="main-payButton-btn-text"
                style={{
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Enter Details and Pay ₹ 100
              </div>
            </div>
          </Link>
        </div>
      ) : null}

      <Creatives />
      <OurClients color={true} />
      <div className="bottom-mobile-main">
        <Reviews
          reviewData={reviewData}
          reviewOpened={reviewOpened}
          setLinks={setLinks}
        />

        <FAQs faqData={faqData} setLinks={setLinks} faqOpened={faqOpened} />
        <WriteReview color={true} />
        <YoutubePlayer
          links={links}
          opts={opts}
          onReady={onReady}
          setLinks={setLinks}
        />
      </div>

      {/* <div className="main-faq-heading-container phone">
                <span>Device </span>
                <span style={{ fontWeight: "800" }}>specifications</span>
            </div> */}
      {/* <Feature /> */}
      <Footer utmQuery={utmQuery} />
    </div>
  );
}

const FAQs = ({ faqData, setLinks, faqOpened }) => (
  <>
    <div className="main-faq-heading-container" style={{ color: "#000" }}>
      <span>Frequently asked </span>
      <span style={{ fontWeight: "800" }}>Questions</span>
    </div>
    <div className="main-faq-review">
      {faqData.map((e) => {
        if (e.hide !== true) {
          return (
            <div
              className="main-faq-video"
              onClick={() => {
                setLinks(e.link);
                faqOpened(e);
              }}
              style={{ cursor: "pointer", background: "#FFF", color: "#000" }}
            >
              <div className="main-faq-img">
                <img src={serverUrl.u + e.imageLink} />
              </div>
              <div style={{ color: "#000" }} className="main-faq-rightSide">
                <div className="head" style={{ color: "#000" }}>
                  {e.header}
                </div>
                <div className="subHead" style={{ color: "#272727" }}>
                  {e.subheader}
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  </>
);
const Reviews = ({ reviewData, setLinks, reviewOpened }) => (
  <>
    <div className="main-review-heading-container" style={{ color: "#000" }}>
      <span>Our </span>
      <span style={{ fontWeight: "800" }}>Reviews</span>
    </div>
    <div className="main-faq-review">
      {reviewData.map((e) => {
        return (
          <div
            className="main-review-video"
            onClick={() => {
              setLinks(e.videoId);
              reviewOpened(e);
            }}
            style={{ cursor: "pointer", background: "#FFF", color: "#000" }}
          >
            <div style={{ color: "white" }} className="main-review-rightSide">
              <div className="head" style={{ color: "#000" }}>
                {e.reviewName}
                <img src={serverUrl.u + e.reviewStatusUserImage} />
              </div>
              <div className="date" style={{ color: "#272727" }}>
                {moment(e.reviewDate).format("DD MM YYYY")}
              </div>
              <div className="main-review-star">
                {Array.from({ length: e.reviewRating }).map((ele) => {
                  return (
                    <img
                      src={process.env.PUBLIC_URL + "./MobileAssets/Vector.png"}
                      alt="star"
                    />
                  );
                })}
              </div>
              <div className="subHead" style={{ color: "#272727" }}>
                {e.reviewText}
              </div>
            </div>
            <div className="main-review-img">
              <img src={serverUrl.u + e.reviewImage} />
            </div>
          </div>
        );
      })}
    </div>
  </>
);

const YoutubePlayer = ({ links, opts, onReady, setLinks }) =>
  links !== "" ? (
    <div
      style={{
        position: "fixed",
        top: "10%",
        width: "95%",
        marginLeft: "2.5%",
      }}
    >
      <div
        style={{
          color: "white",
          float: "right",
          marginBottom: "5px",
          padding: "3px 8px",
          fontSize: "22px",
          borderRadius: "50%",
          fontFamily: "Manrope",
          cursor: "pointer",
        }}
        onClick={() => setLinks("")}
      >
        x
      </div>
      <YouTube videoId={links} opts={opts} onReady={onReady} />
    </div>
  ) : null;

const WriteReview = ({ color }) => (
  <div className="main-write-review" style={{ background: "#FFF" }}>
    {" "}
    <a
      href={`http://wa.me/+919664861809?text=Hi`}
      target="_blank"
      style={{ textDecoration: "none", color: "#000" }}
    >
      Write a review
    </a>
  </div>
);

const Footer = ({ utmQuery }) => {
  return (
    <div className="bottom-wab-main">
      <div className="container-large">
        <img
          src={process.env.PUBLIC_URL + "/DesktopAssets/Aliste_Logo.webp"}
          loading="lazy"
          alt=""
          className="footer-logo"
        />
        <div className="main-footer-text">
          Elevate the comfort of your home. Aliste Helps you make your home
          smart without making you burn a hole through your pocket.Our aim is to
          redefine the way people live by imparting luxury and maximising
          convenience through our products. Our Smart Home product line and
          mobile applications have been lauded by our users and industry -
          experts. Join us in making the Indian home, a more secure &amp;
          comfortable place to live.
        </div>
        <div className="footer-main-container">
          <div className="footer-main-left-Side">
            <div className="footer-column footer-row">
              <div className="footer-title-light">
                Buy now and get the best smart home solution
              </div>
              <a
                href="tel:18002128806"
                className="button-sign-up-2-copy-copy w-button"
                onClick={CallToKnowMore}
              >
                <strong className="bold-text-17">Call us</strong>&nbsp;to know
                more
              </a>
              <div className="main-logo-socials">
                <a
                  href="https://www.facebook.com/alistetechnologies/"
                  className="social-link w-inline-block"
                  onClick={SMFacebook}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/DesktopAssets/faceBook.svg"}
                    loading="lazy"
                    alt="Facebook"
                    className="sm-icon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/alistetechnologies/"
                  className="social-link w-inline-block"
                  onClick={SMInstagram}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/DesktopAssets/Instagram.svg"
                    }
                    loading="lazy"
                    alt="Instagram"
                    className="sm-icon"
                  />
                </a>
                <a
                  id="w-node-cc26770a-4e6f-8d0f-5b3f-32ee26744627-26744611"
                  href="https://www.linkedin.com/company/alistetechnologies"
                  className="social-link w-inline-block"
                  onClick={SMLinkedIn}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/DesktopAssets/LinkedIn.svg"}
                    loading="lazy"
                    alt="Linkedin"
                    className="sm-icon"
                  />
                </a>
                <a
                  id="w-node-cc26770a-4e6f-8d0f-5b3f-32ee26744629-26744611"
                  href="https://wa.me/+919664861809"
                  className="social-link w-inline-block"
                  onClick={SMWhatsApp}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/DesktopAssets/what.svg"}
                    loading="lazy"
                    alt="WhatsApp"
                    height="Auto"
                    className="sm-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="main-footer-right-Side">
            <div
              id="w-node-cc26770a-4e6f-8d0f-5b3f-32ee2674462c-26744611"
              className="footer-column"
            >
              <div className="footer-title-light">Company</div>
              <a
                href={`https://www.alistetechnologies.com/privacy-policy${
                  utmQuery ? utmQuery : ""
                }`}
                className="footer-text-link"
              >
                Privacy Policy
              </a>
              <a
                href={`https://www.alistetechnologies.com/user-agreement${
                  utmQuery ? utmQuery : ""
                }`}
                className="footer-text-link"
              >
                User Agreement
              </a>
              <a
                href={`https://www.alistetechnologies.com/subscriptionterms${
                  utmQuery ? utmQuery : ""
                }`}
                className="footer-text-link"
              >
                Terms of Use
              </a>
            </div>
            <div className="footer-column">
              <div className="footer-title-light">Product</div>
              <a
                href={`https://www.alistetechnologies.com/our-app${
                  utmQuery ? utmQuery : ""
                }`}
                className="footer-text-link"
              >
                Our App
              </a>
              <a
                href={`https://www.alistetechnologies.com/${
                  utmQuery ? utmQuery : ""
                }#devices`}
                className="footer-text-link"
              >
                Our Devices
              </a>
              <a
                href={`https://www.alistetechnologies.com/${
                  utmQuery ? utmQuery : ""
                }#pricing`}
                className="footer-text-link"
              >
                Our Pricing
              </a>
            </div>
            <div className="footer-column">
              <div className="footer-title-light">Marketing</div>
              <a
                href={`https://www.alistetechnologies.com/${
                  utmQuery ? utmQuery : ""
                }#clients`}
                className="footer-text-link"
              >
                Our Clients
              </a>
              <a
                href={`https://www.alistetechnologies.com/${
                  utmQuery ? utmQuery : ""
                }#investor`}
                className="footer-text-link"
              >
                Our Investors
              </a>
              <a
                href={`https://www.alistetechnologies.com/${
                  utmQuery ? utmQuery : ""
                }#FAQ`}
                className="footer-text-link"
              >
                Our FAQ
              </a>
            </div>
          </div>
        </div>
        <div className="footer-title-light-copy">
          Copyright © Aliste since 2018. All rights reserved.
        </div>
      </div>
    </div>
  );
};
