import React, { useEffect, useState } from "react";
import MainV3 from "./MainV3";
import MainV2 from "./MainV2";
import Main from "./Main";
import MainV4 from "./webApp/MainV4";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loadBasicDetails,
  loadFrequency,
  loadPlan,
  loadTypeOfInstallation,
} from "../redux/questionAcrion";
import {
  planFrequencyImages,
  planType,
  planTypeImage,
} from "./webApp/Data/data";

function MainPage() {
  let [minutes, setMinute] = useState(new Date().getMinutes());
  let [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    let minute = new Date().getMinutes();
    setMinute(minute);
  }, []);
  const showPage = () => {
    setShow(true);
  };
  useEffect(() => {
    if (location.pathname === "/plan") {
      dispatch(loadPlan(planType[0]));
      dispatch(loadTypeOfInstallation(planTypeImage["1 Room"][1]));
      dispatch(loadFrequency(planFrequencyImages[1]));
      dispatch(loadBasicDetails({ name: "" }));
      setTimeout(showPage, 2000);
    } else {
      setShow(true);
    }
  }, [location]);
  return <div>{show && <MainV4 />}</div>;
}

export default MainPage;
