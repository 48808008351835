export const mdata = [
  {
    planName: "1 Room Plan",
    appliances: "4",
  },
  {
    planName: "1 BHK Plan",
    appliances: "8",
  },
  {
    planName: "2 BHK Plan",
    appliances: "12",
  },
  {
    planName: "3 BHK Plan",
    appliances: "18",
  },
  {
    planName: "4 BHK Plan",
    appliances: "22",
  },
];

export const fixedAppliances = {
  room1: 4,
  bhk1: 8,
  bhk2: 12,
  bhk3: 18,
  bhk4: 22,
  bhk5: 26
};

export const PlanCost = [
  {
    planName: "Monthly",
    cost: 2,
    days: 30,
    freeDays:0
  },
  {
    planName: "Quarterly",
    cost: 2,
    days: 90,
    freeDays:0
  },
  {
    planName: "Semi Annualy",
    cost: 2,
    days: 180,
    freeDays:20
  },
  {
    planName: "Annualy",
    cost: 2,
    days: 365,
    freeDays:60
  },
];

export const cityArray = [
  {value:"BENGALURU",name:"Bengaluru"},
  {value:"DELHI",name:"Delhi"},
  {value:"GURGAON",name:"Gurgaon"},
  {value:"NOIDA",name:"Noida"},
  {value:"GHAZIABAD",name:"Ghaziabad"},
  {value:"FARIDABAD",name:"Faridabad"},
  {value:"GREATER NOIDA",name:"Greater Noida"},
  {value:"MUMBAI",name:"Mumbai"},
  {value:"OTHERS",name:"Others"},
]
