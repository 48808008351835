import React from 'react'
import './BlueTick.scss'

function BlueTick() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none" className='tickBlue'>
            <path d="M4.94603 0H4.94577C2.2143 0 0 2.22811 0 4.97662V4.97688C0 7.72539 2.2143 9.9535 4.94577 9.9535H4.94603C7.6775 9.9535 9.8918 7.72539 9.8918 4.97688V4.97662C9.8918 2.22811 7.6775 0 4.94603 0Z" fill="#2050FF" />
            <path d="M3.12064 4.42103C3.1856 4.42103 3.22947 4.45539 3.27214 4.49402C3.55809 4.75203 3.84592 5.00855 4.12949 5.2687C4.18 5.31501 4.20584 5.31821 4.25919 5.26998C5.03877 4.56444 5.82072 3.86104 6.6022 3.15721C6.73664 3.03621 6.80848 3.03621 6.94268 3.15679C7.13923 3.33349 7.33602 3.50998 7.53187 3.68754C7.6388 3.78443 7.63809 3.85592 7.5295 3.95345C6.46943 4.90697 5.40936 5.86027 4.34881 6.81337C4.22789 6.92199 4.15463 6.92157 4.03489 6.81337C3.48198 6.31399 2.9293 5.81439 2.37662 5.3148C2.25522 5.20489 2.25475 5.14023 2.37472 5.03181C2.57507 4.85105 2.77613 4.67072 2.97624 4.48975C3.01679 4.45304 3.05947 4.42167 3.12064 4.42124V4.42103Z" fill="white" />
        </svg>
    )
}

export default BlueTick