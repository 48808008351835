import React from 'react'
import { WhatsAppClicked } from '../../../../../utils/fb'
import './Header.scss'

function Header({isSticky=false}) {
  return (
    <div className='HeaderContainer_v4' style={{borderBottom:isSticky?'none':''}}>
    <div className="logo_Contaniner_v4">
        <img
            src={process.env.PUBLIC_URL + "/MobileAssets/logo.png"}
            alt="logo"
        />
    </div>
    <div>Aliste</div>
   
        <div>
            <a
                href={`http://wa.me/+919664861809?text=Hi`}
                target="_blank"
                onClick={WhatsAppClicked}
                style={{ textDecoration: "none", color: "white" }}
            >
                <img
                    className='whatsapp_v4'
                    src={process.env.PUBLIC_URL + "/MobileAssets/Whatapp.png"}
                    alt="logo"
                />
            </a>
        </div>
  
</div>
  )
}

export default Header