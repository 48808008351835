import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { pageView, subscribed } from "../../utils/fb";
import "./Summary.css";
import "./Confirmation.css";
import bottomConfirmLogo from "./confirm_bottom_logo.webp";
import upi from "./upi.webp";
import paid from "./Paid.webp";
import axios from "axios";
import { serverUrl } from "../../ApiUrl";
import html2canvas from "html2canvas";

export default function Confirmation2() {
  let { generatedHex, security, installation } = useParams();
  let [userData, setUserData] = useState();
  let disable = false;
  let [sendSubscribeEvent, setSubscribeEvent] = useState(false);
  useEffect(() => {
    axios
      .get(`${serverUrl.u}/api/subscription/details/${generatedHex}`)
      .then(function (response) {
        setUserData(response.data.data);
        setSubscribeEvent(true);
      })
      .catch(function (error) {});
  }, [generatedHex]);

  let [domBtn, setDomBtn] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    pageView({ name: "Confirmation", location: window.location.toString() });
  }, []);

  const domtml = () => {
    setDomBtn(true);
    setTimeout(function () {
      image();
    }, 2000);
    const image = () => {
      html2canvas(document.getElementById("content"), {
        scale: 2,
      }).then(function (canvas) {
        let link = document.createElement("a");
        link.download = `${userData?.subscription.name}.png`;
        link.href = canvas.toDataURL("image/png");
        link.click();
        setDomBtn(false);
      });
    };
  };
  let Discount = 0;
  let planName;
  let days;
  let duration;
  let offer = "";
  let planId;
  let planFrequency;
  security = userData?.subscription.security;
  installation = userData?.subscription.installation;
  planId = userData?.subscription.planId;
  planFrequency = userData?.subscription.planFrequency;
  switch (planId) {
    case 0:
      planName = "Custom";
      break;
    case 1:
      planName = "1 Room";
      break;
    case 2:
      planName = "1 BHK";
      break;
    case 3:
      planName = "2 BHK";
      break;
    case 4:
      planName = "3 BHK";
      break;
    case 5:
      planName = "4 BHK";
      break;
    default:
      break;
  }
  switch (planFrequency) {
    case "Monthly":
      days = 30;
      duration = "Monthly";
      break;
    case "Quarterly":
      days = 90;
      duration = "Quarterly";
      offer = "+ 10 Days Free";
      break;
    case "Semi Annualy":
      days = 180;
      duration = "Semi Annualy";
      offer = "+ 1 Month Free";
      break;
    case "Annualy":
      days = 365;
      duration = "Annualy";
      offer = "+ 3 Months Free";
      break;
    case "Two Years":
      days = 730;
      duration = "Two Years";
      offer = "+ 6 Months Free";
      break;
    case "Three Years":
      days = 1095;
      duration = "Three Years";
      offer = "+ 9 Months Free";
      break;
    case "tm1":
      days = 7;
      duration = "Test Weekly";
      break;
    default:
      break;
  }

  useEffect(() => {
    if (sendSubscribeEvent) {
      let rate = days * Number(userData?.subscription.totalAppliances);
      let taxable =
        Number(userData?.subscription.totalAppliances) * days +
        Number(installation);
      let tax = taxable * 0.18;
      let total = taxable + tax + Number(security);
      subscribed({
        total,
        tax,
        installation,
        planId: duration,
        packageId: planId,
        rate,
        appliances: userData?.subscription.totalAppliances,
        generatedHex: userData?.subscription.generatedHex,
      });
    }
  }, [sendSubscribeEvent]);

  return (
    <>
      {disable === false ? <div id="download"></div> : null}
      {userData ? (
        <div
          className="CustomPlan2-container"
          ref={ref}
          id="content"
          style={{}}
        >
          <div className="payment-confirmation-header">
            <div className="payment-confirmation-welcome">Welcome Onboard</div>
            <div className="payment-confirmation-welcome-sub">
              Thanks for joining us,{" "}
              <strong>{userData?.subscription.name}</strong>
            </div>
            <img
              src={process.env.PUBLIC_URL + "/svg/summary line.svg"}
              className="confirmation-hr"
            ></img>
            <div className="payment-confirmation-welcome-sub1">
              Aliste Technologies Pvt. Ltd. Payment Reciept
            </div>
          </div>
          <div className="paymentPlans-container">
            {
              userData?.subscription.partialPayment===false?
            <div className="">
              <div className="">
                <div className="confirmation-plan-container">
                  <div className="confirmation-planName-rate-container">
                    <div className="confirmation-planName">{planName} Plan</div>

                    <div className="confirmation-planRate">{`₹ ${userData.subscription.totalAppliances}/day`}</div>
                  </div>
                  <div className="confirmation-planDetail">{`Total ${userData.subscription.totalAppliances} Appliances Automated`}</div>
                  <img
                    src={process.env.PUBLIC_URL + "/svg/summary line.svg"}
                    style={{
                      position: "relative",
                      top: "-0vh",
                      width: "99%",
                      objectFit: "fill",
                    }}
                  ></img>

                  <div className="confirmation-planRate-offer">
                    {userData?.subscription.planFrequency} <span>{offer}</span>
                  </div>
                </div>
              </div>
              <div className="confirmation-plan-toPay">
                <div className="confirmation-details">
                  <div className="confirmation-detail-title">
                    {planName} Plan
                  </div>
                  <div className="confirmation-detail-rate">
                    ₹ {userData.subscription.zohoRecursiveAmount}
                  </div>
                </div>

                <div className="confirmation-details">
                  <div className="confirmation-detail-title">
                    Security deposit{" "}
                    <span style={{ fontWeight: 500 }}>(Refundable)</span>
                  </div>
                  <div className="confirmation-detail-rate">
                    ₹{" "}
                    {userData.subscription.partialAmount +
                      userData.subscription.zohoSecurity}
                  </div>
                </div>
                <div className="confirmation-details">
                  <div className="confirmation-detail-title">
                    Installation fee
                  </div>
                  <div className="confirmation-detail-rate">
                    ₹ {userData.subscription.zohoInstallation}
                  </div>
                </div>
                {Discount > 0 ? (
                  <div className="confirmation-details">
                    <div className="confirmation-detail-title">Discount</div>
                    <div
                      className="confirmation-detail-rate"
                      style={{ color: "rgba(33, 154, 81, 0.9)" }}
                    >
                      - ₹ {Discount}
                    </div>
                  </div>
                ) : null}

                <div className="confirmation-details">
                  <div className="confirmation-detail-title">
                    Taxes & charges
                  </div>
                  <div className="confirmation-detail-rate">
                    ₹{" "}
                    {(userData.subscription.zohoInstallation +
                      userData.subscription.zohoRecursiveAmount) *
                      0.18}
                  </div>
                </div>
                <hr
                  className="confirmation-hr"
                  style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                ></hr>
                <div className="confirmation-details-img">
                  <div className="confirmation-detail-rate1">Total Bill</div>
                  <div className="confirmation2-Paid-png-container">
                    <img
                      className="confirmation-Paid-png"
                      src={paid}
                      alt="paid"
                    />
                  </div>
                  <div className="confirmation-detail-rate1">
                    ₹{" "}
                    {(
                      (userData.subscription.zohoInstallation +
                        userData.subscription.zohoRecursiveAmount) *
                        1.18 +
                      userData.subscription.partialAmount +
                      userData.subscription.zohoSecurity
                    ).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>:
            <>
            <div className="confirmation-plan-container">
                  <div className="confirmation-planName-rate-container">
                    <div className="confirmation-planName">{planName} Plan</div>

                    <div className="confirmation-planRate">{`₹ ${userData.subscription.totalAppliances}/day`}</div>
                  </div>
                  <div className="confirmation-planDetail">{`Total ${userData.subscription.totalAppliances} Appliances Automated`}</div>
                  <img
                    src={process.env.PUBLIC_URL + "/svg/summary line.svg"}
                    style={{
                      position: "relative",
                      top: "-0vh",
                      width: "99%",
                      objectFit: "fill",
                    }}
                  ></img>

                  <div className="confirmation-planRate-offer">
                    {userData?.subscription.planFrequency} <span>{offer}</span>
                  </div>
                </div>
            <div className="payment-confirmation-Booking-container">
              <div className="payment-confirmation-Booking-title">
                Booking Amount
              </div>
              <div className="confirmation-Paid-png-container">
                <img className="confirmation-Paid-png" src={paid} alt="paid" />
              </div>
              <div className="payment-confirmation-Booking-title">₹ {userData?.subscription?.partialAmount}</div>
            </div>
            </>
            }
            {
              userData?.subscription.partialPayment===false?
              <div className="payment-confirmation-details">
              <div className="payment-confirmation-specific">
                <div className="payment-confirmation-specific-left">
                  Payment ID
                </div>
                <div className="payment-confirmation-specific-right">
                  {userData.zohoSubscription?.paytm.gateway_reference_id}
                </div>
              </div>
              <div className="payment-confirmation-specific">
                <div className="payment-confirmation-specific-left">
                  Subscription ID
                </div>
                <div className="payment-confirmation-specific-right">
                  {userData.zohoSubscription?.subscription_id}
                </div>
              </div>
              <div className="payment-confirmation-specific">
                <div className="payment-confirmation-specific-left">
                  Payment Mode
                </div>
                <div className="payment-confirmation-specific-right">
                  {userData.zohoSubscription?.paytm.payment_method}
                </div>
              </div>
              <div className="payment-confirmation-specific">
                <div className="payment-confirmation-specific-left">
                  Payment Gateway
                </div>
                <div className="payment-confirmation-specific-right">
                  {userData.zohoSubscription?.paytm.payment_gateway}
                </div>
              </div>
              <div className="payment-confirmation-specific">
                <div className="payment-confirmation-specific-left">
                  Phone Number
                </div>
                <div className="payment-confirmation-specific-right">
                  {userData.subscription.phone}
                </div>
              </div>
            </div>:
            <div className="payment-confirmation-details">
            <div className="payment-confirmation-specific">
              <div className="payment-confirmation-specific-left">
                Payment ID
              </div>
              <div className="payment-confirmation-specific-right">
              {userData?.paymentLinkDetails?.payments[0]?.payment_id}
              </div>
            </div>
            <div className="payment-confirmation-specific">
              <div className="payment-confirmation-specific-left">
                Refference ID
              </div>
              <div className="payment-confirmation-specific-right">
                {userData?.subscription.generatedHex}
              </div>
            </div>
            <div className="payment-confirmation-specific">
              <div className="payment-confirmation-specific-left">
                Payment Mode
              </div>
              <div className="payment-confirmation-specific-right">
                {userData?.paymentLinkDetails?.payments[0].method}
              </div>
            </div>
            <div className="payment-confirmation-specific">
              <div className="payment-confirmation-specific-left">
                Payment Gateway
              </div>
              <div className="payment-confirmation-specific-right">
                Razorpay
              </div>
            </div>
            <div className="payment-confirmation-specific">
              <div className="payment-confirmation-specific-left">
                Phone Number
              </div>
              <div className="payment-confirmation-specific-right">
                {userData.subscription.phone}
              </div>
            </div>
          </div>
            }
            
            {domBtn === false ? (
              <div
                className="payment-confirmation-download-btn-wab"
                onClick={domtml}
              >
                <div className="payment-confirmation-download-btn-text">
                  Download Receipt
                </div>
              </div>
            ) : null}
          </div>
          <div className="payment-confirmation-botton-cantainer">
            <div className="payment-confirmation-botton-cantainer-img">
              <img src={bottomConfirmLogo} alt="bottom logo"></img>
            </div>
            <div className="payment-confirmation-botton-cantainer-img1">
              <img src={upi} alt="bottom logo"></img>
            </div>
            <div className="payment-confirmation-botton-cantainer-text">
              Aliste is India’s Fastest-growing Home Automation firm. Driven by
              our firm commitment to excellence and innovation, our technology
              enables cost-conscious automation of the common Indian home
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
