import { questions } from "../actionType"

export const loadPlanReducer =(state={},action)=>{
    const {type,payload} = action
   switch(type){
      case questions.LOAD_PLAN:
         return payload
      default:
         return state
   }
}
export const loadTypeOfInstallationReducer =(state={},action)=>{
    const {type,payload} = action
   switch(type){
      case questions.LOAD_TYPE_OF_INSTALLATION:
         return payload
      default:
         return state
   }
}
export const loadFrequencyReducer =(state={},action)=>{
    const {type,payload} = action
   switch(type){
      case questions.LOAD_SELECTED_FREQUENCY:
         return payload
      default:
         return state
   }
}
export const loadBasicDetailsReducer =(state={},action)=>{
   const {type,payload} = action
  switch(type){
     case questions.LOAD_BASIC_DETAILS:
        return payload
     default:
        return state
  }
}