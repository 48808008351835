import React, { useState } from "react";
import "./FAQ.scss";
import CommonHeader from "../Header/CommonHeader";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../../components/ui/accordion";
import { faqDataWeb } from "../../Data/data";

function AccordionS({ data }) {
  return (
    <Accordion type="single" collapsible className="mt-[30px] rounded-[6px]  ">
      <AccordionItem value="item-1" className="">
        <AccordionTrigger className="bg-[#FFF] border-[0.5px] border-[#0000004D] border-l-[5px] rounded-t-[6px] border-l-[#242B52] pl-[10px] pr-[10px] h-[61px] text-[16px] font-[700] text-left hover:no-underline">
          {data.QA}
        </AccordionTrigger>
        <AccordionContent className="bg-[#D7DFF133] text-[14px] font-[500] pl-[10px] pr-[10px] pt-[12px]">
          {data.Ans.map((e) => (
            <div key={e}>
              {e}
              <br></br>
            </div>
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
const FAQName = ({ name, activeFaq, setActiveFaq }) => {
  return (
    <div
      className="faq-freqq"
      style={{
        backgroundColor: activeFaq === name ? "#2552B2" : "#FFFFFF",
        margin: 0,
        border: activeFaq === name ? "" : "1px solid rgb(176 185 208)",
      }}
      key={name}
      onClick={() => setActiveFaq(name)}
    >
      <div
        className="fre"
        style={{
          color: activeFaq === name ? "#FFF" : "#000",
          cursor: "pointer",
        }}
      >
        {name}
      </div>
      {/* <div className='rate' style={{ color: activeFrequency?.planName === data.planName ? "#0CDA5E" : "#208C4B" }}>₹ {rate}/day</div> */}
    </div>
  );
};

function FAQ() {
  const [activeFaq, setActiveFaq] = useState(Object.keys(faqDataWeb)[0]);
  return (
    <div className="FaQ-container bg-white">
      <CommonHeader text="FAQs" />
      <div className="md:h-[30px]"></div>
      <div className="frequency-container scrollbar">
        {Object.keys(faqDataWeb).map((e) => {
          return (
            <FAQName
              key={e}
              name={e}
              activeFaq={activeFaq}
              setActiveFaq={setActiveFaq}
            />
          );
        })}
      </div>

      {faqDataWeb[activeFaq].map((e, index) => {
        return <AccordionS key={String(index)} data={e} />;
      })}
    </div>
  );
}

export default FAQ;
