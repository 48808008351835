import React, { useRef, useState } from 'react'
import './Captcha.css'
import { useLocation } from 'react-router-dom'

function Captcha({setCaptchaSuccess,captcha ,setCaptcha, captchaEnter, setCaptchaEnter,captchaError}) {
  const location = useLocation()
   const inputRef = useRef(null)

   const refrshString = ()=>{
     setCaptcha(Math.random().toString(36).slice(8))
   }
     
  return (
    <>
    <div className="templateUiContainer ">

        <div className='templateUi captchBorder'>
        <div className='color captchLine'>
           {captcha}
        </div>
         <div className='color' onClick={()=>refrshString()}>
              ↻
         </div>
         </div>
        <div className='inputCaptcha'>
                    <div className='inputButton'>
                        <input 
                         placeholder="Enter Captcha Value" 
                         type="text" 
                         className="inputBox captchInput   newinputBox p-2"
                         name="captchCode"
                         style={{
                          borderRadius:'6px',
                          border:
                            captchaError === false
                              ? (location.state!==null?((location.state.path==="3" || location.state.path==="4")?"0.5px solid #00000054":"0.5px solid rgba(255, 255, 255, 0.8)"):"0.5px solid #00000054"
                              )
                              : (location.state!==null?((location.state.path==="3" || location.state.path==="4")?"0.5px solid #00000054":"0.5px solid rgba(255, 255, 255, 0.8)"):"0.5px solid #00000054"
                              ),
                        }}
                         value={captchaEnter}
                         onChange={(e)=>setCaptchaEnter(e.target.value)}
                         autoComplete='current-captchCode'
                       />
                   
                   {captchaError === true ? (
      <div className="field-error" style={{ paddingBottom: "15px" }}>
        Please enter a valid captcha.
      </div>
    ) : null}
                   
                  
                       </div>
                    
        </div>
    </div>
    
    </>
  )
}

export default Captcha
