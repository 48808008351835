import React from 'react'
import Lottie from "lottie-react";
import tick from '../../../assets/lottie/Tick.json'

function Animation() {
  return (
    <Lottie
       animationData={tick}
       loop={false}
       autoPlay
       width={100}
       height={100}
       colorFilters={
       [
         {
         keypath: "Tick Circle",
         
         }
       ]
     }
       style={{
        position:"relative",
        height:'100%',
        padding:0
       }}
       color= "#2050FF"
    />
  )
}

export default Animation