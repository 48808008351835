import React from 'react'
import './CommonHeader.scss'

function CommonHeader({text="dummy",fs='',mL=''}) {
  return (
    <div className='header' style={{fontSize:fs,paddingLeft:mL}}>
       {text}
    </div>
  )
}

export default CommonHeader