import React, { useEffect, useState } from 'react'
import './ImgContainer.scss'
import './ImgContainer1.scss'

function ImgContainer1({ active, data, plan, setPlan }) {
  const mediaMatch = window.matchMedia('(min-width: 760px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  useEffect(()=>{
    window.addEventListener('resize',()=>{
      const mediaMatch = window.matchMedia('(min-width: 760px)');
      setMatches(mediaMatch.matches)
    })
  },[])
    const style = {
        imgContainer: {
        
          border: plan.name === data.name ? "2px solid rgba(32, 80, 255, 0.80)" : "2px solid #AAA",
          background: plan.name === data.name ? "rgba(32, 80, 255, 0.18)" : "#FFF", 
          marginBottom:`{${matches&&(data.name==="4 BHK" || data.name==="5 BHK")?"0px":"20px"}}`
        },
       
    
      }
  return (
    <div 
     className='imgBtn'
     style={{ ...style.imgContainer }}
     onClick={() => setPlan(data)}
  >
    <div className='imgg'>
      <img
        src={data.image}
      />
    </div>
    <div className='textImg'>
      <span className='texttext'>{data.name}</span>
    </div>
  </div>
  )
}

export default ImgContainer1