import React, { useEffect, useState } from "react";
import "./LeadGeneration.scss";
import { ExclamtionIcon } from "./LeadGeneration";
import { isValidNumber } from "../MobileSummary";
import { useDispatch } from "react-redux";
import { trackContact } from "../../../utils/fb";
import { loadBasicDetails } from "../../redux/questionAcrion";
import { cityArray } from "../MobileData";
import emailIcon from "../../../assets/V4/inputBox/email.png";
import userIcon from "../../../assets/V4/inputBox/profile.png";
import locationIcon from "../../../assets/V4/inputBox/location.png";
import contactIcon from "../../../assets/V4/inputBox/contact.png";
import addressComplete from "../../../assets/V4/inputBox/addressComplete.png";
import installationIcon from "../../../assets/V4/inputBox/Installationdate.png";
import watchIcon from "../../../assets/V4/inputBox/watch.png";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { timeSlot } from "../webApp/Data/data";

function InpurtForm({ setSubmit }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [phone, setPhone] = useState("");
  const [selectPopMobileCity, setSelectPopMobileCity] = useState(false);
  const [slot,setSlot] = useState('')
  const [slotError,setSlotError] = useState(false)
  const [phoneError, setPhoneError] = useState(true);
  const [city, setCity] = useState("Select City");
  const [cityError, setCityError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [address, setAdress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [installationDate, setInstallationDate] = useState("");
  const [installationDateError, setInstallationDateError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const startTime = new Date();
      const endTime = new Date();
      
      // Set start time to 10:30 AM
      startTime.setHours(10, 55, 0, 0);

      // Set end time to 7:00 PM
      endTime.setHours(19, 0, 0, 0);

      if (now >= startTime && now <= endTime) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    checkTime();

    // Check every minute
    const interval = setInterval(checkTime, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const checkEmail = (val) => {
    if (
      val
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setEmailError(false);
    } else setEmailError(true);
  };
  const checkData = (click = false) => {
    let res = isValidNumber(phone);
    let c = false;
    let n = false;
    let e = false;
    let slotslot = false;
    let ins = false;
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      e = false;
      setEmailError(false);
    } else {
      e = true;
      setEmailError(true);
    }
    if (city === "Select City") {
      c = true;
    } else if (city !== "Select City") {
      c = false;
    }
    if (name === "") {
      n = true;
    } else if (name !== "") {
      n = false;
    }
    // if (location.pathname === "/contact") {
      if (slot === "") {
        slotslot = false;
      }else if(slot !== ""){
        slotslot = false
      }
     
    // }
    if (click) {
      // if (
      //   res === true &&
      //   n === false &&
      //   c === false &&
      //   location.pathname !== "/contact" &&
      //   slotslot === false
      // ) {
      //   dispatch(
      //     loadBasicDetails({
      //       name: name,
      //       city: city,
      //       phone: phone,
      //       email: email,
      //     })
      //   );

      //   trackContact({
      //     name: name,
      //     email: email,
      //     phone: phone,
      //     city: city,
      //   });
      //   setSubmit(true);
      // }
      if (
        res === true &&
        n === false &&
        c === false &&
        // location.pathname === "/contact" &&
        slotslot === false
      ) {
        dispatch(
          loadBasicDetails({
            name: name,
            city: city,
            phone: phone,
            email: "",
            page: "webapp-contact-us",
          })
        );

        trackContact({
          name: name,
          email: "",
          phone: phone,
          city: city,
          note: "",
          page: "webapp-contact",
        });
        setSubmit(true);
      }
      if (res !== phoneError) {
        setPhoneError(res);
      }
      if (c !== cityError) {
        setCityError(c);
      }
      if (n !== nameError) {
        setNameError(n);
      }
      if (slotslot !== slotError) {
        setSlotError(slotslot);
      }
    }
    if (res === true && n === false && c === false) {
      return true;
    } else {
      return false;
    }
  };
  function capitalizeFirstLetter(string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className="lead__form-container">
      <div className="lead__form">
        <div
          className="lead__input-box relative"
          style={{ backgroundColor: nameError ? "#FFF4F4" : "#FFF" }}
        >
          <img src={userIcon} className="lead__input-icon" />
          <input
            type="text"
            placeholder="Name"
            className="transperent_leadInputBox"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            onBlur={(e) => {
              if (e.target.value !== "") {
                setNameError(false);
              }
            }}
            style={{ color: nameError && "red" }}
          />
          {nameError && <ExclamtionIcon />}
        </div>
        {nameError === true ? (
          <div className="field-error">Please enter a valid Name.</div>
        ) : null}
        <div>
          <div
            className="lead__input-box relative"
            style={{
              backgroundColor: cityError ? "#FFF4F4" : "#FFF",
              borderBottomLeftRadius: selectPopMobileCity && 0,
              borderBottomRightRadius: selectPopMobileCity && 0,
            }}
            onClick={() => setSelectPopMobileCity(!selectPopMobileCity)}
          >
            <img src={locationIcon} className="lead__input-icon" />
            <div
              placeholder="City"
              className="transperent_leadInputBox"
              value={city}
              onClick={() => setSelectPopMobileCity(!selectPopMobileCity)}
              style={{
                cursor: "none",
                alignItems: "center",
                display: "flex",
                color:
                  city === "Select City"
                    ? cityError
                      ? "red"
                      : "#adb2bc"
                    : "black",
              }}
            >
              {capitalizeFirstLetter(city)}
            </div>
            {selectPopMobileCity ? (
              <ChevronUp
                color="#adb2bc"
                onClick={() => {
                  setSelectPopMobileCity(false);
                }}
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "20px",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ChevronDown
                color="#adb2bc"
                onClick={() => setSelectPopMobileCity(true)}
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "20px",
                  cursor: "pointer",
                }}
              />
            )}

            {cityError && <ExclamtionIcon />}
          </div>

          {selectPopMobileCity === true && (
            <div
              className="popBox"
              style={{
                border: "0.5px solid #00000054",
              }}
            >
              {cityArray
                .filter((ci) => ci.value !== city)
                .map((ci) => {
                  return (
                    <div
                      className="selectedValue cityName"
                      style={{
                        height: "43px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 500,
                        color: "black",
                      }}
                      onClick={() => {
                        setCity(ci.value);
                        setSelectPopMobileCity(false);
                        setCityError(false);
                      }}
                    >
                      {ci.name}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        {cityError === true ? (
          <div className="field-error">Please Select Vaild City.</div>
        ) : null}
        <div
          className="lead__input-box relative"
          style={{ backgroundColor: !phoneError ? "#FFF4F4" : "#FFF" }}
        >
          <img src={contactIcon} className="lead__input-icon" />
          <input
            type="number"
            className="transperent_leadInputBox"
            placeholder="Phone Number"
            pattern="\d*"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            onBlur={(e) => setPhoneError(isValidNumber(e.target.value))}
            style={{ color: !phoneError && "red" }}
          />
          {!phoneError && <ExclamtionIcon />}
        </div>

        {phoneError === false ? (
          <div className="field-error">
            {phone.length < 10
              ? phone.length === 0
                ? "Phone Number is Required"
                : "Phone Number Minimum of 10 Digit"
              : "Please enter a valid phone number."}
          </div>
        ) : null}
           {/* {location.pathname !== "/contact" && (
            <>
        <div
          className="lead__input-box relative"
          style={{ backgroundColor: emailError ? "#FFF4F4" : "#FFF" }}
        >
          <img src={emailIcon} className="lead__input-icon" />
          <input
            type="text"
            placeholder="Email"
            className="transperent_leadInputBox"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onBlur={(e) => checkEmail(e.target.value)}
            style={{ color: emailError && "red" }}
          />
          {emailError && <ExclamtionIcon />}
        </div>
        {emailError === true ? (
          <div className="field-error">
            {email.length == 0
              ? "Email is Required"
              : !email.includes("@")
              ? "@ must be included in your email"
              : "Please enter a valid email."}
          </div>
        ) : null}
        </>)
       } */}
        {/* {location.pathname === "/contact" && ( */}
          {/* <>
          <div style={{color:"#272a2dd9"}}>Select Slot for Callback</div>

          <div className="flex w-100 gap-[20px] md:justify-between flex-col md:flex-row">
   
            <div
              className="lead__input-box relative cursor-pointer md:w-[48%!important]"
              style={{ 
                  backgroundColor: slot===timeSlot[1] ? "rgba(32, 80, 255, 0.18)" : "#FFF",
                  border:slot===timeSlot[1] ? '2px solid rgba(32, 80, 255, 0.8)':'none',
                  color:slot===timeSlot[1] ? 'black' : "rgb(173, 178, 188)",
                  alignItems:'center' 
                }}
              onClick={()=>{
                setSlot(timeSlot[1])
                setSlotError(false)
              }}
            >
              <img src={watchIcon} className="lead__input-icon md:w-[30px!important]" />
              <div className="time-text">{timeSlot[1]}</div>
            </div>
            <div
              className="lead__input-box relative cursor-pointer md:w-[48%!important]"
              style={{ 
                backgroundColor: slot===timeSlot[2] ? "rgba(32, 80, 255, 0.18)" : "#FFF",
                border:slot===timeSlot[2] ? '2px solid rgba(32, 80, 255, 0.8)':'none',
                color:slot===timeSlot[2] ? 'black' : "rgb(173, 178, 188)",
                alignItems:'center' 
              }}              onClick={()=>{
                setSlot(timeSlot[2])
                setSlotError(false)
              }}
            >
              <img src={watchIcon} className="lead__input-icon md:w-[30px!important]"/>
              <div className="time-text">{timeSlot[2]}</div>
            </div>
            </div>
            <div className="flex w-100 md:justify-between gap-[20px] flex-col md:flex-row md:gap-0">
            <div
              className="lead__input-box relative cursor-pointer md:w-[48%!important]"
              style={{ 
                backgroundColor: slot===timeSlot[3] ? "rgba(32, 80, 255, 0.18)" : "#FFF",
                border:slot===timeSlot[3] ? '2px solid rgba(32, 80, 255, 0.8)':'none',
                color:slot===timeSlot[3] ? 'black' : "rgb(173, 178, 188)",
                alignItems:'center' 
              }}
              onClick={()=>{
                setSlot(timeSlot[3])
                setSlotError(false)
              }}
            >
              <img src={watchIcon} className="lead__input-icon md:w-[30px!important]"/>
              <div className="time-text">{timeSlot[3]}</div>
            </div>
            <div
              className="lead__input-box relative cursor-pointer md:w-[48%!important]"
              style={{ 
                backgroundColor: slot===timeSlot[4] ? "rgba(32, 80, 255, 0.18)" : "#FFF",
                border:slot===timeSlot[4] ? '2px solid rgba(32, 80, 255, 0.8)':'none',
                color:slot===timeSlot[4] ? 'black' : "rgb(173, 178, 188)",
                alignItems:'center' 
              }}              onClick={()=>{
                setSlot(timeSlot[4])
                setSlotError(false)
              }}
            >
              <img src={watchIcon} className="lead__input-icon md:w-[30px!important]"/>
              <div className="time-text">{timeSlot[4]}</div>
            </div>
            
            </div>

          { isVisible && (
                        <div className="flex w-100 md:justify-between gap-[20px] flex-col md:flex-row md:gap-0">

            <div
              className="lead__input-box relative cursor-pointer w-[100%] md:w-[48%]"
              style={{ 
                  backgroundColor: slot===timeSlot[0] ? "rgba(32, 80, 255, 0.18)" : "#FFF",
                  border:slot===timeSlot[0] ? '2px solid rgba(32, 80, 255, 0.8)':'none',
                  color:slot===timeSlot[0] ? 'black' : "rgb(173, 178, 188)",
                  alignItems:'center' 
                }}
              onClick={()=>{
                setSlot(timeSlot[0])
                setSlotError(false)
              }}
            >
              <img src={watchIcon} className="lead__input-icon md:w-[30px!important]" />
              <div className="time-text">{timeSlot[0]}</div>
            </div>
            </div>)
           }
           
            {slotError === true ? (
          <div className="field-error">Please Select Time Slot.</div>
        ) : null}
          </> */}
      
        <button
          className="lead__callback-button"
          onClick={() => checkData(true)}
        >
          Speak with an Expert
        </button>
      </div>
    </div>
  );
}

export default InpurtForm;
