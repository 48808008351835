import React, { useEffect, useState } from "react";
import "./LeadGeneration.scss";

import AlisteLogo from "../../../assets/LeadGeneration/Aliste_icon.png";
import getInTouchIcon from "../../../assets/LeadGeneration/get_in_touch.png";
import CoustomerReview from "../webApp/comom/customerReview/CoustomerReview";
import GetInTouch from "../../../assets/LeadGeneration/lead_generation_success_get_in_touch.webp";
import FAQ from "../webApp/comom/FAQ/FAQ";
import OurClients from "../../small Component/OurClients";
import Banner from "../webApp/comom/Banner/Banner";
import MiddleBanner from "../webApp/comom/MiddleBanner/MiddleBanner";
import ProductHighLight from "../webApp/comom/ProductHighLight/ProductHighLight";
import CommonHeader from "../webApp/comom/Header/CommonHeader";
import OurMarket from "../../small Component/OurMarket";
import { Footer } from "../Main";
import { ExclamationCircleFill } from "react-bootstrap-icons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LeadGenerationDesktop from "./LeadGenerationDesktop";
import Animation from "./Animation";
import InpurtForm from "./InpurtForm";
import Config from "../../../config/config";
import { markEmailClicked } from "../../../utils/misc";
import { AnimatePresence, motion } from "framer-motion";
export const ExclamtionIcon = () => (
  <ExclamationCircleFill
    color="red"
    style={{
      position: "absolute",
      right: "5%",
      top: "35%",
      zIndex: 10,
    }}
  />
);
export default function LeadGeneration() {
  const mediaMatch = window.matchMedia("(min-width: 760px)");
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [submit, setSubmit] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const location = useLocation();
  const slides = [
    'Transform your existing appliances into smart devices without any rewiring',
    "Our subscription is perfect for rented accommodations as well",
    "We pride ourselves on providing the best customer service",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 4000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);
  const [searchParams, setSearchParams] = useSearchParams();

  const utmSource = searchParams.get("utm_source");
  const utmName = searchParams.get("utm_user");
  const utmMedium = searchParams.get("utm_medium");

  useEffect(() => {
    window.addEventListener("resize", () => {
      const mediaMatch = window.matchMedia("(min-width: 760px)");
      setMatches(mediaMatch.matches);
    });
    let params = new URLSearchParams(window.location.search);
    let utmSource = params.get("utm_source");
    if (utmSource === Config.AlistEmailSource) {
      let emailId = params.get("utm_medium");
      let email = params.get("email");
      let messageId = params.get("messageId");
      markEmailClicked({ emailId, email, messageId });
    }
  }, []);

  return (
    <>
      {matches ? (
        <LeadGenerationDesktop />
      ) : (
        <div className="lead__main-container">
          <div className="lead__aliste_logo">
            <img src={AlisteLogo} className="lead__aliste_logo" />
          </div>
          {submit ? (
            <SuccessRequestCallback />
          ) : (
            <div className="lead__container">
              <div className="lead__content">
                <div
                  className="lead__content_title"
                  style={{ overflowWrap: "anywhere" }}
                >
                  <div className="lead__content-title-bold">
                    {utmSource === "Referral" ? (
                      <span>
                        You were referred by {utmName ? utmName : utmMedium} 🎉
                      </span>
                    ) : (
                      <span>Get in touch</span>
                    )}
                  </div>
                  {utmSource === "Referral" ? null : (
                    <div
                      className="lead__content-title-light"
                      style={{ fontWeight: 500 }}
                    >
                      with us
                    </div>
                  )}
                </div>
                <div className="lead__content-text">
                  {/* Your Smart Home<br />
                  is just a few <br />
                  clicks away */}
                  <AnimatePresence mode="wait" >
                    <motion.p
                    style={{width:'60%',height:'70px'}}
                      key={currentSlide}
                      initial={{ opacity: 0,  }}
                      animate={{ opacity: 1,duration:3}}
                      exit={{ opacity: 0}}
                      transition={{
                        ease: "linear",
                  
                        delay:1.2
                      }}
                    >
                      {slides[currentSlide]}
                    </motion.p>
                  </AnimatePresence>
                  <img src={getInTouchIcon} className="lead__get-in-touch" />
                </div>
              </div>

              <InpurtForm setSubmit={setSubmit} />
            </div>
          )}
        </div>
      )}

      <div className="AsSeen-Container">
        <div className="innerASeen">
          <div className="md:pt-3 md:pb-3">
            <CommonHeader
              text="As seen on"
              fs={
                window.matchMedia("(max-width: 460px)").matches
                  ? "16px"
                  : "26px"
              }
              mL={window.matchMedia("(max-width: 460px)").matches ? "5%" : "0%"}
            />
          </div>
          <OurMarket color={true} mT={0} bg={"#FFF"} bs={"none"} />
        </div>
      </div>
      {
        location.pathname.includes('/contact-us') &&   <>
        <div className="ProductHeighLight">
          <div className="innerProductHeighLight">
            <CommonHeader text="Product Highlights" />
            <ProductHighLight />
          </div>
        </div>
        <MiddleBanner />
        <Banner />
        <div className="OurClinets">
          <OurClients color={true} mT={0} />
        </div>
        <div className="webCustomerReview">
          <FAQ />
        </div>
        <div className="webCustomerReview">
          <CoustomerReview />
        </div>
        </>
      }
    
    
      <Footer />
    </>
  );
}

export const SuccessRequestCallback = ({ timeOut = true }) => {
  const navigate = useNavigate();

  const navigateFun = () => {
    navigate("/");
  };
  useEffect(() => {
    if (!timeOut) return;
    setTimeout(navigateFun, 3000);
  }, []);
  return (
    <div className="lead__success-main-container">
      <div className="lead__success-success-container">
        {/* <img src={SuccessIcon} className="img__lead-success-icon" /> */}
        <div className="lead_animation_tick">
          <Animation />
        </div>
        <h2 className="lead__success-title">Thank You!</h2>
        <p className="lead__success-text">
          Someone from the team will get in touch with you shortly
        </p>
      </div>

      <div className="lead__success-box">
        <h3 className="lead__success-box-title">
          Don’t wait for the callback from us
        </h3>

        <p className="lead__success-box-text">
          Answer 3 simple questions and get the{" "}
          <span className="lead__success-box-blue">best plan</span> for you
        </p>

        <img src={GetInTouch} className="lead__success-getInTouch" />

        <button className="lead__success-button" onClick={() => navigateFun()}>
          Get the best plan
        </button>
      </div>
    </div>
  );
};
