import React, { useEffect, useState } from "react";
import "./CoustmerReview.scss";
import CommonHeader from "../Header/CommonHeader";
import StarGolden from "./StarGolden";
import Star from "./Star";
import {
  motion,
  inView,
  useAnimate,
  useInView,
  useMotionValue,
  useTransform,
  animate as ani,
} from "framer-motion";
import SmallStat from "./SmallStat";
import Button from "../button/Button";
import Reviews from "../rewies/Reviews";
import { useNavigate } from "react-router";
import StarRatings from "react-star-ratings";
import Ani from "./Ani";

function StarProgressBar({ number = 1, w = "80%", animate, isInView }) {
  let element = document.getElementById(`${number}statr`);

  useEffect(() => {
    const fun = async () => {
      if (isInView) {
        await animate(element, {
          width: 0,
        });

        await animate(element, {
          width: w,
        });
      }
    };
    fun();
  }, [isInView]);

  return (
    <div className="singleRowRating">
      <div className="ratingNumber">{number}</div>
      <SmallStat />
      <div className="progreesOuter">
        <motion.div
          className="goldBar"
          id={`${number}statr`}
          style={{ width: w }}
          initial={{
            width: 0,
          }}
          transition={{
            duration: 1,
          }}
        ></motion.div>
        <div className="innerBar"></div>
      </div>
      <div className="starPercentage">{w}</div>
    </div>
  );
}

function CoustomerReview() {
  const navigate = useNavigate();
  const [scope, animate] = useAnimate();
  const isInView = useInView(scope);
  const mediaMatch = window.matchMedia("(min-width: 760px)");
  const [matches, setMatches] = useState(mediaMatch.matches);
  const navDone = () => {
    window.open(
      "http://wa.me/+919664861809?text=Hi",
      "_blank",
      "rel=noopener noreferrer"
    );
  };

  return (
    <div className="customerReview-Container">
      <CommonHeader text="Customer Reviews" />
      <div className="reviewBothContainer">
        <div className="rating-contaoner">
          <div className="overallH">Overall Rating</div>
          <div className="star-rating-container">
            <div className="rating-text">4.7</div>
            <div>
              <div className="star-container">
                {/* {
                        Array.from({length:4}).map(()=>(
                            <StarGolden height={matches?'44':'24'} width={matches?'45':'25'}/>
                        ))
                    }
                    <Star/> */}
                <StarRatings
                  rating={4.7}
                  starRatedColor="#FFCC48"
                  // changeRating={this.changeRating}
                  numberOfStars={5}
                  starDimension="25px"
                  name="rating"
                />
              </div>
              <div className="overallR">Based on 2,553 reviews</div>
              {/* <Ani/> */}
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className="gap-[11.97px] md:gap-[14px] "
            ref={scope}
          >
            <StarProgressBar
              number={5}
              w={"94%"}
              animate={animate}
              isInView={isInView}
            />
            <StarProgressBar
              number={4}
              w={"2%"}
              animate={animate}
              isInView={isInView}
            />
            <StarProgressBar
              number={3}
              w={"2%"}
              animate={animate}
              isInView={isInView}
            />
            <StarProgressBar
              number={2}
              w={"1%"}
              animate={animate}
              isInView={isInView}
            />
            <StarProgressBar
              number={1}
              w={"1%"}
              animate={animate}
              isInView={isInView}
            />
          </div>
        </div>
        <div className="reviewRightContainer">
          <Reviews />

          <Button
            text={"Write a Review"}
            done={navDone}
            bgColor={"#FFF"}
            color={"#000"}
            boxShodow={"0px 0.797px 14.344px 0px rgba(0, 0, 0, 0.08)"}
          />
        </div>
      </div>
    </div>
  );
}

export default CoustomerReview;
