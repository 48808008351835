import React from 'react'
import './OurClients.css'
import Marquee from "react-fast-marquee";
import county from '../../assets/blackIcon/county.png'
import taj from '../../assets/blackIcon/taj.png'
import stanza from '../../assets/blackIcon/stanza.png'
import houser from '../../assets/blackIcon/housr.png'
import giftr from '../../assets/blackIcon/gyftr.png'
 
export default function OurClients({color,mT}) {
  return (
    


    <div className='OurClients-container' style={{marginTop:mT}}>
        <div className='OurClients-haeding' style={{color:color?"#000":""}}>Our Clients</div>
        <div className='ourClient'>
           {/* <marquee loop  behavior="scroll" className="marquee-client"> */}
           <Marquee className="marquee-client" speed={60}>
            <div className='client-logo-container'>
              <img className='ourClientImg1' src={color?stanza:process.env.PUBLIC_URL+'./Clients/somany.png'}/>
              <img className='ourClientImg2' src={color?taj:process.env.PUBLIC_URL+'./Clients/taj.png'}/>
              <img className='ourClientImg3' src={color?giftr:process.env.PUBLIC_URL+'./Clients/gyftr.png'}/>
              <img className='ourClientImg4' src={color?county:process.env.PUBLIC_URL+'./Clients/County.png'}/>
              <img className='ourClientImg5' src={color?houser:process.env.PUBLIC_URL+'./Clients/housr.png'}/>
              
              </div>
              </Marquee>
           {/* </marquee> */}
        </div>
    </div>
    

  )
}
