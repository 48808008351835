import React from 'react'

function IInfo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
  <g opacity="0.7">
    <path d="M4.78125 6.18762V3.93762H3.65625V4.50012H4.21875V6.18762H3.375V6.75012H5.625V6.18762H4.78125Z" fill="#568AF8"/>
    <path d="M4.5 2.25C4.41656 2.25 4.335 2.27474 4.26562 2.3211C4.19624 2.36745 4.14217 2.43334 4.11024 2.51043C4.07831 2.58752 4.06995 2.67234 4.08623 2.75418C4.10251 2.83601 4.14269 2.91119 4.20169 2.97019C4.26069 3.02919 4.33586 3.06937 4.4177 3.08564C4.49953 3.10192 4.58436 3.09357 4.66144 3.06164C4.73853 3.02971 4.80442 2.97563 4.85078 2.90626C4.89713 2.83688 4.92188 2.75531 4.92188 2.67188C4.92188 2.55999 4.87743 2.45268 4.79831 2.37356C4.71919 2.29445 4.61189 2.25 4.5 2.25Z" fill="#568AF8"/>
    <path d="M4.5 8.4375C3.72124 8.4375 2.95996 8.20657 2.31244 7.77391C1.66493 7.34126 1.16025 6.7263 0.862226 6.00682C0.564206 5.28733 0.48623 4.49563 0.63816 3.73183C0.790089 2.96803 1.1651 2.26644 1.71577 1.71577C2.26644 1.1651 2.96803 0.790089 3.73183 0.63816C4.49563 0.48623 5.28733 0.564206 6.00682 0.862226C6.7263 1.16025 7.34126 1.66493 7.77391 2.31244C8.20657 2.95996 8.4375 3.72124 8.4375 4.5C8.4375 5.54429 8.02266 6.54581 7.28423 7.28423C6.54581 8.02266 5.54429 8.4375 4.5 8.4375ZM4.5 1.125C3.83249 1.125 3.17997 1.32294 2.62495 1.69379C2.06994 2.06464 1.63735 2.59174 1.38191 3.20845C1.12646 3.82515 1.05963 4.50375 1.18985 5.15843C1.32008 5.81312 1.64151 6.41448 2.11352 6.88649C2.58552 7.35849 3.18689 7.67993 3.84157 7.81015C4.49626 7.94038 5.17486 7.87354 5.79156 7.61809C6.40826 7.36265 6.93536 6.93007 7.30621 6.37505C7.67706 5.82004 7.875 5.16751 7.875 4.5C7.875 3.6049 7.51942 2.74645 6.88649 2.11352C6.25355 1.48058 5.39511 1.125 4.5 1.125Z" fill="#568AF8"/>
  </g>
</svg>
  )
}

export default IInfo