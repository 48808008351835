import React from 'react'
import './ProductHighLight.scss'
import energy from '../../../../../assets/V4/ProductHighLight/energy saving.png'
import time from '../../../../../assets/V4/ProductHighLight/Time Based.png'
import app from '../../../../../assets/V4/ProductHighLight/Control.png'
import occupancy from '../../../../../assets/V4/ProductHighLight/Occupancy.png'
import voice from '../../../../../assets/V4/ProductHighLight/Voice Control.png'

function ProductHighLight() {
  return (
    <>
      {
        window.matchMedia("(max-width: 460px)").matches ? (
          <div className='productHighLightContainer'>

            <div className='topImage'>
              <img
                src={energy}
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              />
            </div>
            <div className='middleImageContaier'>
              <div className='middleLargeImage'>
                <img
                  src={app}
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}

                />
              </div>
              <div className='middleImageSmall'>
                <div className='smallImageee-container'>
                  <img
                    className='smallImageee'
                    src={occupancy}
                  />
                </div>
                <div className='smallImageee-container'>
                  <img
                    className='smallImageee'
                    src={time}
                  />
                </div>
              </div>

            </div>
            <div className='bottomImage'>
              <img
                src={voice}
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}

              />
            </div>

          </div>
        ) : (
          <div className='container webContainer'>
            <div className='row'>
              <div className='col-md-9 gap-6 d-grid'>
                <div className='row'>
                  <div className='col-md-8'>
                    <img
                      className='smallImageee'
                      src={energy}
                      style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    />
                  </div>
                  <div className='col-md-4'>
                    <img
                      className='smallImageee'
                      src={occupancy}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <img
                      className='smallImageee'
                      src={time}
                    />
                  </div>
                  <div className='col-md-8'>
                    <img
                      className='smallImageee'
                      src={voice}
                      style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <img
                      className='smallImageee'
                  src={app}
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                />
              </div>
            </div>
          </div>
        )
      }

    </>
  )
}

export default ProductHighLight