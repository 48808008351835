import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import { planType } from '../../Data/data'
import './Model.scss'

function Model({data, plan, setPlan,btnName,OnClick,page,width,question,ImgContainer,Arrow="",onBack,delay=0.5,durationTime=0.7}) {
    const mediaMatch = window.matchMedia('(min-width: 760px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  useState(()=>{
      window.addEventListener('resize',()=>{
        const mediaMatch = window.matchMedia('(min-width: 760px)');
        setMatches(mediaMatch.matches)
      })
  },[])
    return (
        <motion.div  
          animate={{
            backgroundColor: "rgba(8, 8, 8, 0.4)",
            WebkitBackdropFilter: "blur(5px)",
            backdropFilter: "blur(5px)",
          }}
          transition={{
            delay:delay+durationTime
          }}
          className='popContainer'
        >
            <motion.div 
             
             initial={{
                height:0
             }}
             animate={{
                height:'auto'
             }}
             transition={{
                duration:durationTime,
                delay:delay
             }}
             className="innerContainer"
            >
              
                <div className='numberContainer relative'>
                {Arrow!=="" &&<span className='arrow' onClick={onBack}> <Arrow/></span>}
            
                    <span className='boldNumber'>{page}</span>
                    <span className='smallNumber' style={{  fontSize: "25.217px", letterSpacing: "0.025px" }}>/</span>
                    <span className='smallNumber'style={{  display: "flex", alignItems: 'flex-end', paddingBottom: "3.5px" }}>04</span>
                </div>
                <div className='lineProgressBar'>
                    <div className='lineProgrssfull'></div>
                    <div className='lineNotFull' style={{width:width}}></div>
                </div>
                <div className='qustionTextContainer' style={{marginBottom:`${matches?(page==="01"?"20px":"40px"):(durationTime===0?"22px":'40px')}`}}>
                    {question}
                </div>
                <div className='imgButtonContainer' style={{padding:`${matches&&(page==="01"?"0px":'0px')}`,gap:`${matches&&(page==="01"?"33px":"39px")}`}}>
                    {
                        data.map(e => {
                            return <ImgContainer active={true} data={e} plan={plan} setPlan={setPlan}/>

                        })
                    }

                </div>
                <div 
                  className='btn1' 
                  onClick={OnClick}
                  style={{
                    backgroundColor:Object.keys(plan).length===0?"rgba(0, 0, 0, 0.125)":"#2050FF",
                    pointerEvents:Object.keys(plan).length===0?"none":"auto"
                  }}
                >
                    {btnName}
                </div>
            </motion.div>
        </motion.div>
    )
}

export default Model