import React from 'react'
import './SmallStat.scss'

function SmallStat() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 12 11" fill="none" className='starReview'>
    <g filter="url(#filter0_d_4241_1138)">
      <path d="M11.1507 3.90389H7.12368L5.87513 0L4.62659 3.90389H0.599609L3.85285 6.31305L2.62189 10.1993L5.87513 7.79019L9.12838 10.1993L7.87984 6.29546L11.1507 3.90389Z" fill="#FFCC48"/>
    </g>
    <defs>
      <filter id="filter0_d_4241_1138" x="0.599609" y="0" width="10.9858" height="10.6342" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={'0'} result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="0.217511" dy="0.217511"/>
        <feGaussianBlur stdDeviation="0.108755"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4241_1138"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4241_1138" result="shape"/>
      </filter>
    </defs>
  </svg>
  )
}

export default SmallStat