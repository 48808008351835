import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react'
import loadder from '../../../../../assets/V4/icons/loadder.json'
import Lottie from "lottie-react";
import './Loading.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px"
};

function Loading() {
   
  return (
    <div style={{
        height:'100svh',
        width:'100vw',
        position:'fixed',
        top:0,
        left:0,
        zIndex:2000000,
        display:'flex',
        justifyContent:'center',
        alignItems:"center",
        flexDirection:"column",
        backdropFilter:'blur(6px)',
        WebkitBackdropFilter: "blur(6px)"
        // background:'#FFF'
    }}>
        
          <div style={{ zIndex:2000000}} className='ani-loading'>
           
            <Lottie 
               animationData={loadder} 
              //  loop={true} 
               height={"10px"}
               width={"10px"}
            />
          
            <div style={{
                fontSize:"22px",
                color:"#000",
                width:'90vw',
                zIndex:2000000,
                margin:'auto',
                textAlign:'center',
                fontWeight:"600"
            }}
            className='labe-lading'
            >
                Curating The Best Smart Home For You
            </div>
            </div>
    </div>
  )
}

export default Loading