import React, { useEffect, useState } from 'react'
import Header from './comom/Header/Header'
import ProductHighLight from './comom/ProductHighLight/ProductHighLight'
import { SuccessRequestCallback } from '../leadGeneration/LeadGeneration'
import AlisteLogo from "../../../assets/LeadGeneration/Aliste_icon.png";
import LeadGenerationDesktop, { HeroFormSuccess } from '../leadGeneration/LeadGenerationDesktop'
import "../leadGeneration/LeadGeneration.scss";
import { useSelector } from 'react-redux';

function Back() {
  const mediaMatch = window.matchMedia('(min-width: 760px)');
  const [matches, setMatches] = useState(mediaMatch.matches)
  const questionUser = useSelector((state) =>  state.questionUser )
  useEffect(() => {
    window.addEventListener('resize', () => {
      const mediaMatch = window.matchMedia('(min-width: 760px)');
      setMatches(mediaMatch.matches)
    })
  }, [])
  return (
    <>
      {
        Object.keys(questionUser).length !== 0 ?
          <>
            {
              matches ? <LeadGenerationDesktop val={true} timeOut={false} /> :
                <>
                  <div className="lead__main-container">
                    <div className="lead__aliste_logo">
                      <img src={AlisteLogo} className="lead__aliste_logo" />
                    </div>
                    <SuccessRequestCallback timeOut={false} />
                  </div>
                </>
            }
          </> :
          <div style={{ background: '#F3F5FA', height: '100svh', width: '100vw', overflow: 'hidden', }}>
            <Header />
            <div style={{ margin: "5%" }}>
              <ProductHighLight />

            </div>
          </div>
      }


    </>
  )
}

export default Back