import React from "react";
import "./Reviews.scss";
import BlueTick from "./BlueTick";
import { reviewsData } from "../../Data/data";
import StarRatings from "react-star-ratings";
import { motion } from "framer-motion";

function Review({
  name = "Nikita Sharma",
  date = "27 Feb 2023",
  star = 5,
  message = "dummy",
}) {
  return (
    <motion.div
      initial={{
        x: "-80%",
      }}
      whileInView={{
        x: "0",
      }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      className="singleReview"
    >
      <div className="reviewerNameContainer">
        <div className="reviewerName">{name}</div>
        <BlueTick />
      </div>
      <div className="reviewDate">{date}</div>
      <div className="starContainer">
        {/* {
                    Array.from({length:star}).map(()=>(
                        <StarReview height={'15'} width={'20'}/>
                    ))
                } */}
        <StarRatings
          rating={5}
          starRatedColor="#FFCC48"
          // changeRating={this.changeRating}
          numberOfStars={5}
          starSpacing="1px"
          starDimension="20px"
          name="rating"
        />
      </div>
      <div className="reviewText">
        <div className="quto letfQuto" style={{}}>
          “
        </div>
        {message}
        <div className="quto rightQuto" style={{}}>
          ,,
        </div>
      </div>
    </motion.div>
  );
}

function Reviews() {
  return (
    <motion.div className="reviews-container scrollbar">
      {reviewsData.map((e) => {
        if (e.sentiment === 5) {
          return (
            <Review key={e.user} name={e.user} date={e.reviewDate} message={e.comment} />
          );
        }
      })}
    </motion.div>
  );
}

export default Reviews;
