import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageView, subscribed } from "../../utils/fb";
import "./Summary.css";
import talk from "./phone boy.webp";
import talk_back from "./talk_us_back.png";

export default function Confirmation() {
  let { security, installation, planId, planFrequency, totalAplliances } =
    useParams();
  useEffect(() => {
    pageView({ name: "Confirmation", location: window.location.toString() });
  }, []);
  let Discount = 0;
  let planName = "1 Room";

  switch (planId) {
    case "0":
      planName = "Custom";
      break;
    case "1":
      planName = "1 Room";
      break;
    case "2":
      planName = "1 BHK";
      break;
    case "3":
      planName = "2 BHK";
      break;
    case "4":
      planName = "3 BHK";
      break;
    case "5":
      planName = "4 BHK";
      break;
    default:
      break;
  }
  let days = 0;
  let duration;
  let offer = "";
  switch (planFrequency) {
    case "a1":
      days = 30;
      duration = "Monthly";
      break;
    case "aq1":
      days = 90;
      duration = "Quarterly";
      offer = "+ 10 Days Free";
      break;
    case "ahy1":
      days = 180;
      duration = "Semi Annualy";
      offer = "+ 1 Month Free";
      break;
    case "ay1":
      days = 365;
      duration = "Annualy";
      offer = "+ 3 Months Free";
      break;
    case "ay2":
      days = 730;
      duration = "Two Years";
      offer = "+ 6 Months Free";
      break;
    case "ay3":
      days = 1095;
      duration = "Three Years";
      offer = "+ 9 Months Free";
      break;
    case "tm1":
      days = 7;
      duration = "Test Weekly";
      break;
    default:
      break;
  }
  let rate = days * Number(totalAplliances);
  let taxable = Number(totalAplliances) * days + Number(installation);
  let tax = taxable * 0.18;
  let total = taxable + tax + Number(security);
  subscribed({
    total,
    tax,
    installation,
    planId: duration,
    packageId: planId,
    appliances: totalAplliances,
  });
  return (
    <div className="CustomPlan-container">
      <div className="Summary-arrow">
        {/* <Link to={'/summary'} style={{ position: "absolute", bottom: "10%" }}>
          <img src={arrow} alt="arrow" />
        </Link> */}
        <div className="summary-head"> Payment Confirmation</div>
      </div>
      <div className="paymentPlans-container">
        <div className="wab-view-payment-summary-container">
          <div className="wab-view-1">
            <div className="summary-plan-container">
              <div className="summary-planName-rate-container">
                <div className="summary-planName">{planName} Plan</div>

                <div className="summary-planRate">{`₹ ${totalAplliances}/day`}</div>
              </div>
              <div className="summary-planDetail">{`Total ${totalAplliances} Appliances Automated`}</div>
              <img
                src={process.env.PUBLIC_URL + "/svg/summary line.svg"}
                style={{
                  position: "relative",
                  top: "-0vh",
                  width: "99%",
                  objectFit: "fill",
                }}
              ></img>

              <div className="summary-planRate-offer">
                {duration} <span>{offer}</span>
              </div>
            </div>
          </div>
          <div className="summary-plan-toPay">
            <div className="summary-details">
              <div className="summary-detail-title">{planName} Plan</div>
              <div className="summary-detail-rate">₹ {rate}</div>
            </div>

            <div className="summary-details">
              <div className="summary-detail-title">
                Security deposit (Refundable)
              </div>
              <div className="summary-detail-rate">₹ {security}</div>
            </div>
            <div className="summary-details">
              <div className="summary-detail-title">Installation fee</div>
              <div className="summary-detail-rate">₹ {installation}</div>
            </div>
            {Discount > 0 ? (
              <div className="summary-details">
                <div className="summary-detail-title">Discount</div>
                <div
                  className="summary-detail-rate"
                  style={{ color: "rgba(33, 154, 81, 0.9)" }}
                >
                  - ₹ {Discount}
                </div>
              </div>
            ) : null}

            <div className="summary-details">
              <div className="summary-detail-title">Taxes & charges</div>
              <div className="summary-detail-rate">₹ {tax}</div>
            </div>
            <hr
              className="summary-hr"
              style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
            ></hr>
            <div className="summary-details-img">
              <div className="summary-detail-rate1">Total Bill</div>
              <div className="Paid-png-container">
                <img
                  className="Paid-png"
                  src={process.env.PUBLIC_URL + "/svg/Paid (1).webp"}
                  alt="paid"
                />
              </div>
              <div className="summary-detail-rate1">₹ {total}</div>
            </div>
          </div>
        </div>
        <div className="talk-to-us" style={{ marginBottom: "1px" }}>
          <div className="sumarry-talk-to-left">
            <div className="summary-talk-title">talk to us!</div>
            <div className="summary-talk-subtitle">
              we will resolve queries at lightning speed
            </div>
            <a href="tel:18002128806" style={{ textDecoration: "none" }}>
              <div className="summary-talk-btn">CALL US NOW</div>
            </a>
          </div>
          <div
            className="sumarry-talk-to-right"
            style={{
              backgroundImage: `url(${talk_back})`,
              backgroundSize: "170px 170px",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img src={talk} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
