import React from 'react'
import './OurMarket.css'
import EconomicTimes from '../../assets/market/EconomicTimes.png'
import Yourstory from '../../assets/market/YourStory.png'
import CNBCTV from '../../assets/market/CNBC.png'
import mint from '../../assets/market/m4.svg'
import ndtv from '../../assets/market/ndtv.png'
import VCCircle from '../../assets/market/vccircle.png'
import sharkTank from '../../assets/market/Shark_Tank.png'
import Zeebiz from '../../assets/market/zee-business.png'
import timesOfIndia from '../../assets/market/Times_of_India.png'
import { useNavigate } from 'react-router-dom';
import Marquee from 'react-fast-marquee'


export default function OurMarket({ color,mT='',bg='',bs="" }) {
  const navigation = useNavigate()
  return (
    <div className='OurMarket-container' style={{marginTop:mT,backgroundColor:bg,boxShadow:bs,marginBottom:mT}}>
            <Marquee className="marquee-client" speed={60}>

      <div className='market-logo-container scrollbar' style={{ marginBottom: 0 }}>
        <img
          src={sharkTank}
          // style={{ width: "44px", height: "46px" }}
          className='img1'
          onClick={() => window.open("https://www.youtube.com/watch?v=YmTe_URrLq8", "_blank")}
        />
        <img
          src={CNBCTV}
          // style={{ width: "36.42px", height: "33.29px" }}
          className='img2'
          onClick={() => window.open("https://www.cnbctv18.com/startup/startup-digest-omidyar-network-the-good-glamm-group-ondc-epic-games-google-18552081.htm", "_blank")}
        />
        <img
          src={EconomicTimes}
          // style={{ width: "60.91px", height: "33.17px" }}
          className='img3'
          onClick={() => window.open("tech-raises-1-million-in-funding-from-yournest-artha-others/amp_articleshow/105928797.cms", "_blank")}
        />
        <img
          src={Yourstory}
          // style={{ width: "71.32px", height: "23.95px" }}
          className='img4'
          onClick={() => window.open("https://yourstory.com/2023/12/startup-news-and-updates-daily-roundup-december-12-2023", "_blank")}
        />
        <img
          src={timesOfIndia}
          style={{ width: "74px", height: "27px" }}
          className='img5'
          onClick={() => window.open("https://timesofindia.indiatimes.com/business/startups/companies/aliste-technologies-raises-1-million-for-smart-home-automation-expansion/articleshow/105930830.cms", "_blank")}
        />
        <img
          src={VCCircle}
          // style={{width: "68.13px", height: "20.94px"}}
          className='img6'
          onClick={() => window.open("https://www.vccircle.com/alistetech-better-space-raise-early-stage-funding?amp=1", "_blank")}
        />
        <img
          src={Zeebiz}
          // style={{ width: "71px", height: "26px" }}
          className='img7'
          onClick={() => window.open("https://www.zeebiz.com/hindi/small-business/shark-tank-india-featured-aliste-technologies-raises-around-rs-83-crore-who-use-to-provide-home-automation-solutions-152940/amp", "_blank")}
        />
        <img
          src={ndtv}
          // style={{ width: "64.58px", height: "12.19px" }}
          className='img8'
          onClick={() => window.open("https://www.youtube.com/watch?v=L-n8mAPxG50", "_blank")}
        />
      </div>
      </Marquee>

    </div>


  )
}
