import React from 'react'

function Rupee({lineHeight}) {
    return (
        <div style={{
            fontFamily: 'Manrope',
            color: "#363636",
            fontSize: "13.146px",
            fontStyle: "normal",
            fontWeight: "500 ",
              lineHeight: '18px', /* 181.818% */
        }}>₹</div>
    )
}

export default Rupee