import React, { useCallback, useEffect, useRef, useState } from "react";
import "./PlanDetail.scss";
import CommonHeader from "../Header/CommonHeader";
import CommonSubHeader from "../Header/CommonSubHeader";
import { duration } from "../../../MainV3";
import monthlyl from "../../../../../assets/V4/planType/1BHK.png";
import feeLogo from "../../../../../assets/V4/planType/subscription.png";
import installationLogo from "../../../../../assets/V4/planType/installation.png";
import securityLogo from "../../../../../assets/V4/planType/security deposit.png";
import totalApplinces from  '../../../../../assets/V4/planType/totalApplinces.png'
import { installationCoast } from "../../Data/data";
import BookingAmount from "../BookingAmount";
import Button from "../button/Button";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { mobileViewPlanDetail } from "../../../../redux/action";
import Rupee from "../Rupee/Rupee";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover";
import IInfo from "./IInfo";
import InnerI from "./InnerI";
import CustomPop from "../../../../../components/ui/CustomPop";
import { loadFrequency } from "../../../../redux/questionAcrion";
import { easeInOut, m, motion, useAnimationControls } from "framer-motion";
import MobileSummary, { isValidNumber, registerLeadAndLinkApi } from "../../../MobileSummary";
import { dummy } from "../../../../../component/Mobile/Data";
import ApplianceIncrementDecrement from "./ApplianceIncrementDecrement";

const PlanName = ({
  freq = "monthly",
  rate = 1,
  activeFrequency,
  setActiveFrequency,
  data,
  typeOfInstallation,
  dispatch,
  plan,
  setDefaultSelectedPlan,
  show,
  referal,
  matches,
}) => {
  const location = useLocation();
  return (
    <div
      className="freqq"
      style={{
        backgroundColor:
          activeFrequency?.planName === data.planName ? "#2552B2" : "#FFFFFF",
        position: "relative",
        minWidth: `${
          matches && location.pathname === "/summary" && "max-content"
        }`,
        padding: `${
          matches && (location.pathname === "/summary" ? "0 2%" : "0 4.7%")
        }`,
        fontSize: `${
          matches && (location.pathname === "/summary" ? "14.7px" : "14px")
        }`,
        padding: "15px",
      }}
      key={freq}
      onClick={() => {
        setActiveFrequency(data);
        dispatch(
          mobileViewPlanDetail({
            appliances: typeOfInstallation.app,
            rate:
              typeOfInstallation.app *
              activeFrequency.cost *
              activeFrequency.days,
            planName: plan.name,
            planId: 0,
            refferal: referal,
          })
        );
        dispatch(loadFrequency(data));
        if (!show) {
          setDefaultSelectedPlan(data);
        }
      }}
    >
      {data.freeDays !== 0 && (
        <div className="freeDaysPlan">
          {" "}
          {data.freeDays === 0 ? "" : `+ ${data.freeDays} Days Free`}
        </div>
      )}
      <div
        className="fre"
        style={{
          color: activeFrequency?.planName === data.planName ? "#FFF" : "#000",
        }}
      >
        {freq}
      </div>
      {"   "}
      {/* <div className='rate' style={{ color: activeFrequency?.planName === data.planName ? "#0CDA5E" : "#208C4B" }}>&nbsp;&nbsp;&nbsp;₹ {rate}/day</div> */}
    </div>
  );
};
const PlanDetailRow = ({
  header = "head",
  subheader = "sub",
  coast = 33,
  imgs = monthlyl,
  classs = "",
  popMessage = "",
  app = 0,
}) => {
  const [pop, setPop] = useState(false);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setPop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div className={`singleRow ${classs}`}>
      <div className="leftContainer">
        <img src={imgs} />
        <div>
          <div className="head">{header}</div>
          <div className="subHead flex items-center">
            {`${subheader} ${app !== 0 ? " For " + app + " Appliances" : ""}`}

            <div onClick={() => setPop(true)} className="ml-2">
              <IInfo />
            </div>
            {pop && (
              <CustomPop
                Icon={<InnerI />}
                header={subheader}
                message={popMessage}
                refs={ref}
                setPop={setPop}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex gap-1 h-[25px] items-start">
        <Rupee lineHeight={"0px"} />{" "}
        <div className="cost">{coast.toFixed(2)}</div>
      </div>
    </div>
  );
};
function PlanDetail({
  refSticky,
  isSticky,
  refRevoke,
  show = true,
  setDefaultSelectedPlan = {},
  discount = 0,
  referal = null,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const controls = useAnimationControls();
  const [shows, setShow] = useState(false);
  const plan = useSelector((state) => state.questionPlan);
  const typeOfInstallation = useSelector(
    (state) => state.questionTypeofIstallation
  );
  console.log(typeOfInstallation);
  const selectedFrequency = useSelector((state) => state.questionFrequency);
  let questionUser = useSelector((state) => state.questionUser);
  const mediaMatch = window.matchMedia("(min-width: 760px)");
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [firstActiveFrequency, setFirstActiveFrequency] =
    useState(selectedFrequency);
  const [activeFrequency, setActiveFrequency] = useState(selectedFrequency);
  const [paymentLink, setPaymentLink] = useState(false);
  const [paymentLinkBtn, setPaymentLinkBtn] = useState(false);
  const navDone = () => {
    // alert('call')
  };
  useEffect(() => {
    if (Object.keys(questionUser).length !== 0) {
      if (
        questionUser.name === undefined ||
        questionUser.city === undefined ||
        questionUser.phone === undefined ||
        questionUser.name === "" ||
        questionUser.city === "" ||
        questionUser.phone === ""
      ) {
        setPaymentLink(false);
      } else {
        if(isValidNumber(questionUser.phone))
        setPaymentLink(true);
      }
    }
  }, [questionUser]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const mediaMatch = window.matchMedia("(min-width: 760px)");
      setMatches(mediaMatch.matches);
    });
  }, []);

  useEffect(() => {
    if (
      activeFrequency === undefined ||
      Object.keys(activeFrequency).length === 0
    )
      return;
    dispatch(
      mobileViewPlanDetail({
        appliances: typeOfInstallation.app,
        rate:
          typeOfInstallation.app * activeFrequency.cost * activeFrequency.days,
        planName: plan.name,
        planId: 0,
        refferal: referal,
      })
    );
  }, [plan, activeFrequency, typeOfInstallation]);
  // if (Object.keys(plan).length === 0 || Object.keys(selectedFrequency).length === 0) return
  return (
    <div style={{ position: "relative" }}>
      <motion.div
        className="planContain"
        initial={{
          x: 0,
        }}
        animate={controls}
        transition={{
          ease: easeInOut,
          duration: 1.2,
        }}
      >
        {!matches && (
          <>
            <CommonHeader text={"Automating  your existing appliances"} />
            <CommonSubHeader
              text={"We don't do any rewiring, our devices simply goes behind your switchBox"}
            />
          </>
        )}

        <div
          className="frequency-container scrollbar"
          style={{
            justifyContent: `${
              matches && (location.pathname === "/summary" ? "unset" : "unset")
            }`,
          }}
        >
          {Object.keys(firstActiveFrequency).length !== 0 && (
            <PlanName
              freq={firstActiveFrequency.planName}
              rate={firstActiveFrequency.cost}
              activeFrequency={activeFrequency}
              setActiveFrequency={setActiveFrequency}
              data={firstActiveFrequency}
              typeOfInstallation={typeOfInstallation}
              dispatch={dispatch}
              plan={plan}
              setDefaultSelectedPlan={setDefaultSelectedPlan}
              show={show}
              referal={referal}
              matches={matches}
            />
          )}
          {Object.keys(firstActiveFrequency).length !== 0 &&
            duration.map((e, i) => {
              if (e.planName !== firstActiveFrequency.planName) {
                return (
                  <PlanName
                    freq={e.planName}
                    rate={e.cost}
                    activeFrequency={activeFrequency}
                    setActiveFrequency={setActiveFrequency}
                    data={e}
                    typeOfInstallation={typeOfInstallation}
                    dispatch={dispatch}
                    plan={plan}
                    setDefaultSelectedPlan={setDefaultSelectedPlan}
                    show={show}
                    referal={referal}
                    matches={matches}
                  />
                );
              }
            })}
        </div>
        <div className="planDetailContainer">
          <ApplianceIncrementDecrement
             header={"Total Appliances"}
             imgs={totalApplinces}
             classs={"topRow"}
             typeOfInstallation={typeOfInstallation}
             subheader={"Calculated at ₹2/appliance/day"}
          />
          <PlanDetailRow
            header="Subscription Fee"
            subheader={activeFrequency?.planName}
            imgs={feeLogo}
            app={typeOfInstallation.app}
            coast={
              typeOfInstallation.app *
              activeFrequency?.cost *
              activeFrequency?.days
            }
            // classs={"topRow"}
            popMessage={
              "The Subscription fee is the amount you pay at the end of every billing cycle"
            }
          />
          <PlanDetailRow
            header="Security Deposit"
            subheader={"Refundable"}
            imgs={securityLogo}
            coast={typeOfInstallation.app * 0}
            popMessage={
              "The security deposit charged at INR 100 per appliance is refundable"
            }
          />
          <PlanDetailRow
            header="Installation Charges"
            subheader={"One Time"}
            imgs={installationLogo}
            coast={installationCoast(typeOfInstallation.app)}
            popMessage={"A one time charge levied for installation services"}
          />
          <div className="line"></div>
          <div className="subTotal-container">
            <div className="subTotalText">
              <div className="subTotal">Subtotal</div>
              <div className="flex gap-1 h-[25px] items-start">
                <Rupee />{" "}
                <div className="cost">
                  {(
                    typeOfInstallation.app *
                      activeFrequency.cost *
                      activeFrequency.days +
                    installationCoast(typeOfInstallation.app) +
                    typeOfInstallation.app * 0
                  ).toFixed(2)}
                </div>
              </div>
            </div>

            <div className="subTotalText">
              <div className="taxes">Taxes & Charges</div>
              <div className="flex gap-1 items-start">
                <div className="taxCoastRupee">₹</div>{" "}
                <div className="taxCoast">
                  {(
                    (typeOfInstallation.app *
                      activeFrequency.cost *
                      activeFrequency.days +
                      installationCoast(typeOfInstallation.app)) *
                    0.18
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            {discount !== 0 && (
              <div className="subTotalText">
                <div className="taxes">Discount</div>
                <div className="flex gap-1 items-start">
                  <div className="taxCoastRupee">₹</div>{" "}
                  <div className="taxCoast">-{discount.toFixed(2)}</div>
                </div>
              </div>
            )}
          </div>
          <div className="line"></div>
          <div className="total-container">
            <div>Total Amount</div>
            <div className="flex gap-1 h-[25px] items-start">
              <Rupee lineHeight={"29px"} />{" "}
              <div className="total-container-coast">
                {(
                  Number(
                    (
                      (typeOfInstallation.app *
                        activeFrequency.cost *
                        activeFrequency.days +
                        installationCoast(typeOfInstallation.app)) *
                        1.18 +
                      typeOfInstallation.app * 0
                    ).toFixed(2)
                  ) - discount
                ).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        {discount === 0 && (
          <BookingAmount
            remaningAmount={(
              (typeOfInstallation.app *
                activeFrequency.cost *
                activeFrequency.days +
                installationCoast(typeOfInstallation.app)) *
                1.18 +
              typeOfInstallation.app * 0 -
              100
            ).toFixed(2)}
            refRevoke={refRevoke}
          />
        )}

        {isSticky && window.matchMedia("(max-width: 460px)").matches && (
          <div style={{ height: "43px", margin: "5%" }}></div>
        )}
        {}
        {show && (
          <Link
            className={`${isSticky ? "sticky" : ""} stic`}
            to={!matches && `/summary`}
            state={{ path: "4", cost: activeFrequency }}
            style={{
              textDecoration: "none",
              width: "90%",
              margin: "auto",
              transition: "ease-in-out",
            }}
            ref={refSticky}
            onClick={() => {
              if (matches && !paymentLink) {
                controls.start({
                  x: "-100vw",
                  opacity: 0,
                });
                setShow(true);
              }
              if(paymentLink){
                console.log(activeFrequency,"activeFrequency");
                let data = {
                    planFrequency: `${activeFrequency.planName==="Monthly"?activeFrequency.planName:`${activeFrequency.planName}2`}`,
                    planId: 0,
                    email: "",
                    refferal: referal === undefined ? null : referal,
                    teleCallerId: "website",
                    first_name: questionUser.name,
                    last_name: " ",
                    phone: questionUser.phone,
                    city: questionUser.city.toUpperCase(),
                    installation: installationCoast(typeOfInstallation.app),
                    security: typeOfInstallation.app * 0,
                    address: "No",
                    layout: dummy[0],
                    leadQuality: "10",
                    origin: "website",
                    comments: " ",
                    zip: "",
                    partialPayment: true,
                    partialAmount: 100,
                    secondarySalesPerson: "empty",
                    discount: 0,
                    orignalRecursiveAmount:
                    typeOfInstallation.app *
                    activeFrequency?.cost *
                    activeFrequency?.days,
                    orignalTotalAmount: Number(
                      (
                        ( typeOfInstallation.app *
                            activeFrequency?.cost *
                            activeFrequency?.days +
                          installationCoast( typeOfInstallation.app)) *
                          1.18 +
                          typeOfInstallation.app * 0
                      ).toFixed(2)
                    ),
                    recursiveAmount:
                    typeOfInstallation.app *
                    activeFrequency?.cost *
                    activeFrequency?.days,
                    totalAmount: Number(
                      (
                        ( typeOfInstallation.app *
                            activeFrequency?.cost *
                            activeFrequency?.days +
                          installationCoast( typeOfInstallation.app)) *
                          1.18 +
                          typeOfInstallation.app * 0
                      ).toFixed(2)
                    ),
                    addOnAmount: 0,
                    totalAppliances:  typeOfInstallation.app,
                  }
                  console.log(data,"fffff");
                  registerLeadAndLinkApi(data,setPaymentLinkBtn)
              }
            }}
          >
            <Button
              text={paymentLink ? "Pay  ₹ 100" : "Enter Details and Pay ₹ 100"}
              done={navDone}
              bgColor={paymentLinkBtn?"#cccccc":"#2552B2"}
              color={paymentLinkBtn?"#000":"#FFF"}
            />
          </Link>
        )}
      </motion.div>
      {shows && (
        <motion.div
          style={{
            position: "absolute",
            left: 0,
            top: "-10px",
          }}
          initial={{
            x: "100vw",
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            ease: easeInOut,
            duration: 1.5,
          }}
        >
          <MobileSummary pageshow={false} />
        </motion.div>
      )}
    </div>
  );
}

export default PlanDetail;
