import React from 'react'
import './Button.scss'

function Button({text,done,bgColor,color,boxShodow="none",}) {
  return (
    <div 

      className='button-container'
      style={{
        backgroundColor:bgColor,
        color,
        boxShadow:boxShodow
      }}
      onClick={done}
    >{text}</div>
  )
}

export default Button