import React from 'react'

function CustomPop({Icon,header,message,refs,setPop}) {
  
  return (
    <div className='z-[10002200200] fixed top-0 left-0 h-full w-full flex items-center justify-center' style={{backdropFilter:'blur(2px)',WebkitBackdropFilter: "blur(2px)"}}>
       <div ref={refs} className='relative h-max p-3 rounded-[12px] bg-white shadow-lg flex justify-center items-center flex-col sm:w-[70%] md:w-[400px] m-auto'>
        <div className=' absolute top-[-24.5px] sm:left-[40%] md:left-[42%] bg-white rounded-[50%]'>
         {Icon}
        </div>
        <div className='mt-[40px] font-bold sm:text-[22px] md:text-[28px] text-black'>
            {header}
        </div>
        <div className='mt-[16px] mb-[20px] text-[#A3A3A3] text-center sm:text-[14px] md:text-[16px] leading-[18px]'>
            {message}
        </div>
        <div 
          onClick={()=>setPop(false)}
          className=' w-[100%] m-auto sm:h-[32px] md:h-[36px] text-center rounded-[12.542px] sm:text-[16px] md:text-[18px] font-semibold text-white flex items-center justify-center' style={{background:'linear-gradient(91deg, #2552B2 25.07%, #0E3589 98.23%)'}}>
            Ok Great
        </div>
       </div>
    </div>
  )
}

export default CustomPop