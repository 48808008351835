import { useDispatch } from 'react-redux'
import { loadTypeOfInstallation } from '../../../../redux/questionAcrion'
import React from 'react'

function ApplianceIncrementDecrement({
    header,
    imgs,
    classs,
    typeOfInstallation,
    subheader
}) {
   const dispatch  = useDispatch()
   const handleAppliance = (type)=>{
    let app = typeOfInstallation.app
    if(app===4 && type===0){
        return
    }
    if(type===0){
        dispatch(loadTypeOfInstallation({
            ...typeOfInstallation,
            app:app-1,
        }))
    }
    if(type===1){
        dispatch(loadTypeOfInstallation({
            ...typeOfInstallation,
            app:app+1,
        }))
    }
   }
  return (
    <div className={`singleRow ${classs}`}>
    <div className="leftContainer">
      <img src={imgs} />
      <div>
        <div className="head">{header}</div>
        <div className="subHead flex items-center">
            {`${subheader}`}         
        </div>
      </div>
      </div>
    <div className="flex rounded-3 gap-1 items-start border border-slate-300 px-2 py-1 ">
        <div className='px-2 cursor-pointer' onClick={()=>handleAppliance(1)}>+</div>
        <div className='font-bold'>{typeOfInstallation.app}</div>
        <div className='px-2 cursor-pointer' onClick={()=>handleAppliance(0)}>-</div>
      </div>
    </div>
  )
}

export default ApplianceIncrementDecrement