export const userResposeData = (data) => {
  return {
    type: "userResponseInfo",
    payload: data,
  };
};

export const userPlanName = (data) => {
  return {
    type: "userPlan",
    payload: data,
  };
};

export const completeData = (data) => {
  return {
    type: "completeData",
    payload: data,
  };
};

export const updateCompleteData = (data) => {
  return {
    type: "updateCompleteData",
    payload: data,
  };
};

// mobile view

export const mobileViewPlanDetail = (data) => {
  return {
    type: "mobileViewPlan",
    payload: data,
  };
};

// Faq data

export const faqDataAction = (data) => {
  return {
    type: "faqDataGet",
    payload: data,
  };
};

// Review Data

export const reviewDataAction = (data) => {
  return {
    type: "reviewDataGet",
    payload: data,
  };
};
