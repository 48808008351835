import React from 'react'

function Arrow() {
  return (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        // width="8" 
        // height="12" 
        viewBox="0 0 8 12" 
        fill="none"
    >
       <path d="M7.21424 1.2576L6.1759 0.225098L0.406738 6.0001L6.18174 11.7751L7.21424 10.7426L2.47174 6.0001L7.21424 1.2576Z" fill="#606060"/>
    </svg>
  )
}

export default Arrow