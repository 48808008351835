import Bhk1 from '../../../../assets/V4/icons/photos/develop/1room_full.png'
import Bhk2 from '../../../../assets/V4/icons/photos/develop/2bhk_fullend.png'
import Bhk3 from '../../../../assets/V4/icons/photos/develop/3bhk_fullend.png'
import Bhk4 from '../../../../assets/V4/icons/photos/develop/4bhk_full.png'

import Full1Bhk from '../../../../assets/V4/icons/photos/develop/1room_full.png'
import Some1Bhk from '../../../../assets/V4/icons/photos/develop/1room_some.png'
import NotMuch1Bhk from '../../../../assets/V4/icons/photos/develop/1room_halfsome.png'
import Uncertain1BHK from '../../../../assets/V4/icons/photos/develop/1room_unsertain.png'

import Full2Bhk from '../../../../assets/V4/icons/photos/develop/2bhk_fullend.png'
import Some2Bhk from '../../../../assets/V4/icons/photos/develop/2bhk_some.png'
import NotMuch2Bhk from '../../../../assets/V4/icons/photos/develop/2bhk_ half_some.png'
import Uncertain2BHK from '../../../../assets/V4/icons/photos/develop/2bhk_uncertain.png'

import Full3Bhk from '../../../../assets/V4/icons/photos/develop/3bhk_fullend.png'
import Some3Bhk from '../../../../assets/V4/icons/photos/develop/3bhk_some.png'
import NotMuch3Bhk from '../../../../assets/V4/icons/photos/develop/3bhk_halfsome.png'
import Uncertain3BHK from '../../../../assets/V4/icons/photos/develop/3bhk_uncertain.png'

import Full4Bhk from '../../../../assets/V4/icons/photos/develop/4bhk_full.png'
import Some4Bhk from '../../../../assets/V4/icons/photos/develop/4bhk_some.png'
import NotMuch4Bhk from '../../../../assets/V4/icons/photos/develop/4bhk_notmuch.png'
import Uncertain4BHK from '../../../../assets/V4/icons/photos/develop/4bhk_uncertain.png'

import Full6Bhk from '../../../../assets/V4/icons/photos/develop/FullEnd 5bhk.png'
import Some6Bhk from '../../../../assets/V4/icons/photos/develop/Some 5bhk.png'
import NotMuch6Bhk from '../../../../assets/V4/icons/photos/develop/NotMuch 5bhk.png'
import Uncertain6BHK from '../../../../assets/V4/icons/photos/develop/Uncertain 5bhk.png'

import Full5Bhk from '../../../../assets/V4/icons/photos/develop/FullEnd 4bhk.png'
import Some5Bhk from '../../../../assets/V4/icons/photos/develop/Some 4bhk.png'
import NotMuch5Bhk from '../../../../assets/V4/icons/photos/develop/NotMuch 4bhk.png'
import Uncertain5BHK from '../../../../assets/V4/icons/photos/develop/Uncertain 4bhk.png'

import monthly from '../../../../assets/V4/icons/photos/Monthly.png'
import quaterly from '../../../../assets/V4/icons/photos/Quarterly.png'
import semiAnnualy from '../../../../assets/V4/icons/photos/semi_anually.png'
import annualy from '../../../../assets/V4/icons/photos/Anually.png'
import { fixedAppliances } from '../../MobileData'

export const planType = [
    {
        image:Bhk1,
        name:'1 Room',
        app:fixedAppliances.room1
    },
    {
        image:Bhk2,
        name:'1 BHK',
        app:fixedAppliances.bhk1,
    },
    {
        image:Bhk3,
        name:'2 BHK',
        app:fixedAppliances.bhk2
    },
    {
        image:Bhk4,
        name:'3 BHK',
        app:fixedAppliances.bhk3,
    },
    {
        image:Full5Bhk,
        name:'4 BHK',
        app:fixedAppliances.bhk4,
    },
    {
        image:Full6Bhk,
        name:'5 BHK',
        app:fixedAppliances.bhk5,
    },
]

export const planTypeImage ={
    "1 Room":[
        {
            image:Full1Bhk,
            name:'Advanced',
            app:10,
        },
        {
            image:Some1Bhk,
            name:'Standard',
            app:9,
        },
        {
            image:NotMuch1Bhk,
            name:'Very Basic',
            app:7,
        },
        {
            image:Uncertain1BHK,
            name:'Not Sure',
            app:6,
        }
    ],
    "1 BHK":[
        {
            image:Full2Bhk,
            name:'Advanced',
            app:16,
        },
        {
            image:Some2Bhk,
            name:'Standard',
            app:12,
        },
        {
            image:NotMuch2Bhk,
            name:'Very Basic',
            app:9,
        },
        {
            image:Uncertain2BHK,
            name:'Not Sure',
            app:6,
        }
    ],
    "2 BHK":[
        {
            image:Full3Bhk,
            name:'Advanced',
            app:22,
        },
        {
            image:Some3Bhk,
            name:'Standard',
            app:18,
        },
        {
            image:NotMuch3Bhk,
            name:'Very Basic',
            app:13,
        },
        {
            image:Uncertain3BHK,
            name:'Not Sure',
            app:6,
        }
    ],
    "3 BHK":[
        {
            image:Full4Bhk,
            name:'Advanced',
            app:26,
        },
        {
            image:Some4Bhk,
            name:'Standard',
            app:22,
        },
        {
            image:NotMuch4Bhk,
            name:'Very Basic',
            app:18,
        },
        {
            image:Uncertain4BHK,
            name:'Not Sure',
            app:6,
        }
    ],
    "4 BHK":[
        {
            image:Full5Bhk,
            name:'Advanced',
            app:32,
        },
        {
            image:Some5Bhk,
            name:'Standard',
            app:26,
        },
        {
            image:NotMuch5Bhk,
            name:'Very Basic',
            app:22,
        },
        {
            image:Uncertain5BHK,
            name:'Not Sure',
            app:6,
        }
    ],
    "5 BHK":[
        {
            image:Full6Bhk,
            name:'Advanced',
            app:36,
        },
        {
            image:Some6Bhk,
            name:'Standard',
            app:30,
        },
        {
            image:NotMuch6Bhk,
            name:'Very Basic',
            app:26,
        },
        {
            image:Uncertain6BHK,
            name:'Not Sure',
            app:6,
        }
    ]
}

export const planFrequencyImages = [
    {
        image:monthly,
        planName: "Monthly",
        name:'Monthly',
        index:0,
        cost: 2,
        days: 30,
        freeDays:0,
    },
    {
        image:quaterly,
        planName: "Quarterly",
        name:'Quarterly',
        index:1,
        cost: 2,
        days: 90,
        freeDays:0,
    },
    {
        image:semiAnnualy,
        planName: "Semi Annualy",
        name:'Semi Annualy',
        index:2,
        cost: 2,
        days: 180,
        freeDays:20,
    },
    {
        image:annualy,
        planName: "Annualy",
        name:'Annualy',
        index:3,
        cost: 2,
        days: 365,
        freeDays:60,
    },
]

export const installationCoast = (appliances)=>{
    let installation = 300
    if (appliances <= 10) {
        installation=300
    } else if (appliances > 10) {
        let app =appliances - 10;
        installation=(300 + app * 20);
    }
    return installation
}

export const faqDataWeb ={
    Product:[
        {
            QA:'How do the automation solutions work?',
            Ans:[
                '1. Switch Based Appliances : A Credit card sized device is installed behind your switchboard which connects to the supply and loads & provides conventional switching as well as Wi-Fi based control.',
                '2. Remote-Based appliances : A Table-top device is installed in front of the appliance and is trained to replicate the respective IR-based commands'
            ]
        },
        {
            QA:'What is the device load bearing capacity?',
            Ans:[
                "The switchboard based automation has a load bearing capacity of 7 Amperes for light loads & 16 Amperes for heavy loads"
                 ]
        },
        {
            QA:'What devices can be automated via remote automation?',
            Ans:[
                'All Standard IR-based Remote operated appliances such as ACs, TVs & Sound systems are compatible with the automation system'
              ]
        },
        {
            QA:'Which Voice assistants is the product line compatible with?',
            Ans:[
                'The Aliste product line is compatible with Amazon Alexa, Apple Siri Shortcuts & Google Assistant. It can easily be integrated with these assistants via their respective applications'
              ]
        }

    ],
    Usage:[
        {
            QA:'What type of network does the device support?',
            Ans:[
                'The device requires a 2.4 GHz WiFi Connection of sufficient strength, Aliste is not liable for irregularities or malfunctions caused due to low quality network'
              ]
        },
        {
            QA:'How do I share access to my smart home?',
            Ans:[
                'Open Settings → Select “House Access” → Enter User Credentials → Select Access Level ( Admin, User or Guest ) → Share complete or partial access (As per requirement)'
              ]
        },
        {
            QA:'How many users can access a single home?',
            Ans:[
                'There is no limit on the number of users that can access a single home'
              ]
        },
        {
            QA:'How to update a device’s WiFi credentials?',
            Ans:[
                'Follow the following steps :',
                '1. Turn off the existing Wi-Fi (If On)',
                '2. Go to the Aliste Mobile app & select “Settings”',
                '3. Select “Update WiFi Password”',
                '4. Enter New WiFi Credentials',
                '5. Create a Hotspot with the credentials : SSID : aliste (Case sensitive) Password : 12345678',
                '6. Once connected wait for the acknowledgement to be received and continue usage'
              ]
        }
       
    ],
    Subscription:[
        {
            QA:'How to recharge my automation plan?',
            Ans:[
                'Open the settings page on the app → Go to “Automation Plan” → Select Renew Plan / Recharge Now → Make the requisite payments'
              ]
        },
        {
            QA:'How will the security deposit be refunded?',
            Ans:[
                'The security deposit is collected at the time of confirmation of subscription & is refunded once a subscription is terminated, thereby ensuring no defect to the product'
              ]
        },
        {
            QA:'Can I pause my subscription?',
            Ans:[
                'You can pause your subscription and defer payments by 30 days in a year, thereby saving on subscription fees when not in use'
              ]
        },
        {
            QA:'What payment modes can be used for the subscription?',
            Ans:[
                'The payment modes available are : UPI, Debit & Credit cards and Gift Vouchers'
              ]
        },
        
    ],
    Installation:[
        {
            QA:'Do I have to change my switchboard to install sync?',
            Ans:[
                'No, we provide you with a complete retrofit solution. The Sync Device goes right behind your existing switchboards without any additional rewiring'
              ]
        },
        {
            QA:'Does your product require any re-wiring?',
            Ans:[
                'No, Our products do not require any rewiring. They are perfectly compatible with your existing switchboard'
              ]
        },
        {
            QA:'How long does it take to install?',
            Ans:[
                'An Aliste product typically takes roughly 5 - 10 minutes to get installed'
              ]
        },
        {
            QA:'Can I continue using my switchboard conventionally with sync?',
            Ans:[
                'Sync does not compromise the functionality of your switchboard, it continues to operate as it always has'
              ]
        },
        {
            QA:'Which appliances are considered heavy-load?',
            Ans:[
                'Appliances with power rating greater than 650W are considered heavy load appliances. Appliances like Geysers, Air conditioners and refrigerators fall in this category'
              ]
        },
       
        
    ],
    Software:[
        {
            QA:'Which platforms is the application available on?',
            Ans:[
                'The Aliste Mobile App is available on the iOS App store and Google Play Store'
              ]
        },
        {
            QA:'Which Voice assistants is the product line compatible with?',
            Ans:[
                'The Aliste product line is compatible with Amazon Alexa & Google Assistant.It can easily be integrated with these assistants via their respective applications'
              ]
        },
        {
            QA:'Is there a limit on the number of devices that can be controlled with the app?',
            Ans:[
                'There is no limit on the number of devices that can be controlled with the application'
              ]
        },
        {
            QA:'How Can I share access to my home with guests?',
            Ans:[
                'You can share the access to your home with guests with the “Share/Select House” option. As the administrator, you can add and remove other users as per your jurisdiction'
              ]
        },
        
    ],
    Warranty:[
        {
            QA:'How much warranty is provided on Sync?',
            Ans:[
                'For the first year, we provide you with free support and warranty. For the second and the third year, you can extend the warranty period further using an AMC of 10% per year. For the fourth year and further, 15% per year will be applicable'
              ]
        },
        {
            QA:'What is the warranty on the Smart Locks?',
            Ans:[
                'The warranty on smart locks is one year, this can be extended further on a surcharge'
              ]
        },
        {
            QA:'What is the warranty on the smart curtains?',
            Ans:[
                'There is a warranty of five years on the smart curtains'
              ]
        },
      
        
    ],
    'Query Unresolved':[
        {
            QA:"Didn't find an answer to the question you were looking for?",
            Ans:[
                'Send in your queries & questions via mail or call us on our helpline for further assistance'
              ]
        },
    ]
}
export const reviewsData = [
    
    {
      "user": "Mayank Pabbi",
      "reviewerType": null,
      "reviews": 1,
      "reviewDate": "5 months ago",
      "criticalFeedback": ["Professionalism", "Punctuality"],
      "comment": "Had to remove this device from my house as it is buggy and they control our home devices whenever they want. My fan and light keep on switching off and on automatically even if I don’t control. Even the manual switches became non-operational after they installed the service. Never trust these subscription-based automation brands.",
      "sentiment": 2
    },
    {
      "user": "Saurabh Garg",
      "reviewerType": "Local Guide",
      "reviews": 47,
      "reviewDate": "a month ago",
      "comment": "I had a truly abysmal experience with Aliste Technologies, and I feel compelled to share my frustration to warn others. I paid an advance amount on December 16th for a service, and the subsequent events have been nothing short of...",
      "sentiment": 1
    },
    {
      "user": "A1 Smart solution",
      "reviewerType": "Local Guide",
      "reviews": 11,
      "reviewDate": "3 months ago",
      "comment": "Hello Aliste technology, We are A1 smart solutions from Hubli Karnataka, around 1 year back we took your distributor for Home automation, we tried our level best but we face some...",
      "sentiment": 3
    },
    {
      "user": "Utsav Kapoor",
      "reviews": 1,
      "reviewDate": "10 months ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness", "Value"],
      "comment": "One of the best products in the market out there. Installed in my grandparents room and they couldn’t have been more grateful as they often had to get out of the bed or call someone to switch on/off the appliances but now at its all of them...",
      "sentiment": 5
    },
    {
      "user": "A1 Smart solution",
      "reviewerType": "Local Guide",
      "reviews": 11,
      "reviewDate": "3 months ago",
      "comment": "Hello Aliste technology, We are A1 smart solutions from Hubli Karnataka, around 1 year back we took your distributor for Home automation, we tried our level best but we face some...",
      "sentiment": 3
    },
    {
      "user": "Sanjay Wadhwa",
      "reviews": 1,
      "reviewDate": "2 years ago",
      "positiveFeedback": ["Quality", "Responsiveness", "Value"],
      "comment": "We had Aliste's automation systems installed at our head office in Delhi and were thoroughly satisfied with the user friendliness of the devices. A great addition to any office or home.",
      "sentiment": 5
    },
    {
      "user": "Flux Experience",
      "reviewerType": "Local Guide",
      "reviews": 19,
      "reviewDate": "7 months ago",
      "criticalFeedback": ["Professionalism", "Quality", "Responsiveness", "Value"],
      "comment": "This is a total waste of money. The devices don’t work at all. It's been more than a month since I got this installed...",
      "sentiment": 1
    },
    {
      "user": "Pratyaksh Gupta",
      "reviews": 6,
      "reviewDate": "10 months ago",
      "positiveFeedback": ["Value"],
      "comment": "The installation process was smooth and hassle-free. I appreciate the personalized optimization and recommendations based on my usage patterns. Aliste truly cares about making a positive impact on the environment.",
      "sentiment": 4
    },
    {
      "user": "Smitha MS",
      "reviews": 1,
      "reviewDate": "10 months ago",
      "comment": "Aliste has made my life so much easier! I can control my home appliances from anywhere and the scheduling feature saves me time and effort. Highly recommend it!",
      "sentiment": 5
    },
    {
      "user": "Rupali Langer",
      "reviews": 3,
      "reviewDate": "10 months ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness", "Value"],
      "comment": "Aliste has made my life so much easier! With just one app, I can control all my appliances and even set up custom scenes. Highly recommend!",
      "sentiment": 5
    },
    {
      "user": "Deepak Mishra",
      "reviews": 1,
      "reviewDate": "10 months ago",
      "positiveFeedback": ["Quality", "Value"],
      "comment": "I was hesitant to try smart home tech due to the high prices, but Aliste's subscription plan is affordable and accessible. I love the added features like the FIFA mode during the World Cup - it's the little things that make a big difference.",
      "sentiment": 4
    },
    {
      "user": "nadia nazrin",
      "reviews": 6,
      "reviewDate": "10 months ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness", "Value"],
      "comment": "Aliste has transformed my home into a smart living space. The easy-to-use interface and compatibility with multiple devices make it a must-have for any smart home enthusiast.",
      "sentiment": 5
    },
    {
      "user": "Aditya Pandoh",
      "reviews": 1,
      "reviewDate": "10 months ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness", "Value"],
      "comment": "I love that Aliste can be retrofitted into my existing home without any major renovations. The installation fee was reasonable and it was up and running in no time.",
      "sentiment": 5
    },
    {
      "user": "Deepanshu Chawla",
      "reviews": 1,
      "reviewDate": "10 months ago",
      "comment": "Aliste is a game-changer for anyone living in a rented accommodation. It's affordable and accessible. I can't imagine going back to a non-smart home now that I've experienced Aliste.",
      "sentiment": 4
    },
    {
      "user": "Zaid Khan",
      "reviews": 5,
      "reviewDate": "a year ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness", "Value"],
      "comment": "Aliste technologies is bringing about a positive change in the home-automation ecosystem. I got their standard pack installed and it is how I would describe as the beginning of a new era of affordable smart home systems in India.",
      "sentiment": 5
    },
    {
      "user": "Abhay Gupta",
      "reviewerType": "Local Guide",
      "reviews": 12,
      "reviewDate": "a year ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness", "Value"],
      "comment": "I hired Aliste Tech for my house & they are very hard working & loyal towards their work. It was quite a good experience with them from start to end. Device, products & services are awesome. Post services are also good.",
      "sentiment": 5
    },
    {
      "user": "Abhinav Sharma",
      "reviews": 8,
      "reviewDate": "a year ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness", "Value"],
      "comment": "Aliste technologies is providing the latest in the market automation solutions. Service was really fast. I could not believe how fast the devices got installed. The cost of the devices is worth it as there is so much work done behind it by the...",
      "sentiment": 5
    },
    {
      "user": "Aamod Nayyar",
      "reviews": 1,
      "reviewDate": "a year ago",
      "positiveFeedback": ["Professionalism", "Quality", "Responsiveness", "Value"],
      "comment": "I’ve used this product. Best in segment and easy to operate. The team is very efficient in terms of delivering and servicing. If you are looking for an amazing home automation asset, Aliste is the jackpot you can hit.",
      "sentiment": 4
    },
    {
      "user": "mahesh madhukar",
      "reviews": 14,
      "reviewDate": "7 months ago",
      "criticalFeedback": ["Responsiveness"],
      "comment": "After payment for installation, I have just received an invoice, been 3 days, no follow up mails or calls from the team to update me on the ETA of installation.",
      "sentiment": 2
    },
    {
      "user": "Prakash Balani",
      "reviews": 1,
      "reviewDate": "a year ago",
      "comment": "Just to inform who has been distributor for country or city wise. You guys are going to REGRET IT. I bought samples products from them which I Paid them almost 70k and they didn’t give me the invoice. I bought the product for UAE market and...",
      "sentiment": 1
    },
    {
      "user": "Yashodhan Chawla",
      "reviews": 2,
      "reviewDate": "3 months ago",
      "criticalFeedback": ["Quality", "Responsiveness"],
      "comment": "They are not returning the security deposit which was to be given back when the service was stopped.",
      "sentiment": 1
    },
    {
      "user": "CHANDAN DATTA",
      "reviews": 6,
      "reviewDate": "a year ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness", "Value"],
      "comment": "I have been using Aliste from the last 6 months and I am fully satisfied with the product which was delivered at par with my expectations. Sync is a flawless product by the company.",
      "sentiment": 5
    },
    {
      "user": "Kunal Pasari",
      "reviewerType": "Local Guide",
      "reviews": 12,
      "reviewDate": "10 months ago",
      "positiveFeedback": ["Quality", "Value"],
      "comment": "Aliste has transformed my home into a smart and efficient space. Highly recommend!!!. Good value product.",
      "sentiment": 5
    },
    {
      "user": "Palak Gupta",
      "reviews": 1,
      "reviewDate": "a year ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness"],
      "comment": "The work done by team ALISTE is quite upstanding. I'm fully satisfied with their services. Even though I was a little skeptical at the start but the way the team explained me everything, the way they were so dedicated in their work and understanding that what all is required in my home, made me believe them fully.",
      "sentiment": 5
    },
    {
      "user": "Akshit Singh",
      "reviews": 1,
      "reviewDate": "a year ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness", "Value"],
      "comment": "At first I was not comfortable and had different thoughts about this product and the company then I automated my own room first later i got it done for my whole house amazing experience",
      "sentiment": 5
    },
    {
      "user": "Dev Singh",
      "reviews": 1,
      "reviewDate": "10 months ago",
      "positiveFeedback": ["Professionalism", "Quality"],
      "comment": "Had a fantastic encounter with the Aliste staff. Their service is excellent, and their product is flawless.",
      "sentiment": 4
    },
    {
      "user": "Aayush Gupta",
      "reviews": 3,
      "reviewDate": "a year ago",
      "positiveFeedback": ["Professionalism", "Quality", "Responsiveness"],
      "comment": "This is an app everyone will love. As a fellow consumer, trust me when I say you will not regret getting Aliste installed in your house.",
      "sentiment": 4
    },
    {
      "user": "Ashveen Kaur",
      "reviews": 2,
      "reviewDate": "a year ago",
      "positiveFeedback": ["Professionalism", "Punctuality", "Quality", "Responsiveness", "Value"],
      "comment": "A fantastic organization! Excellent customer service. All responses were prompt and professional. The team is really informed and went that extra mile at every stage. Would recommend them unreservedly.",
      "sentiment": 5
    },
    {
      "user": "Shrusti Ghela",
      "reviews": 2,
      "reviewDate": "10 months ago",
      "comment": "Aliste is a game-changer! Easy control of all appliances and savings on bills.",
      "sentiment": 4
    }
]  

export const timeSlot  = [
  "Get Call Now",
  "10:00 AM - 12:00 PM",
  "12:00 PM - 02:00 PM",
  "02:00 PM - 04:00 PM",
  "04:00 PM - 06:00 PM"
]


